<div
  class="h-18 p-2 grid grid-cols-4 gap-4 items-center bg-grey-800 overflow-hidden"
>
  <button
    class="flex flex-col gap-1 justify-center items-center h-full p-2 w-full overflow-hidden bg-grey-600 rounded-md text-center transition"
    [matTooltip]="
      selectedBusinessUnit?.displayName || selectedBusinessUnit?.name || ''
    "
    [matTooltipPosition]="'left'"
    [ngClass]="[selectedBusinessUnit ? 'text-warn' : 'text-white']"
    type="button"
    [matMenuTriggerFor]="businessUnitsMenu"
  >
    <mat-icon [svgIcon]="'design:store'"></mat-icon>
    <div
      *ngIf="selectedBusinessUnit"
      class="uppercase truncate w-full text-xs text-white"
    >
      {{ selectedBusinessUnit.displayName || selectedBusinessUnit.name }}
    </div>
  </button>
  <mat-menu #businessUnitsMenu="matMenu">
    <ng-template matMenuContent>
      <div class="flex flex-col flex-grow flex-shrink-0 p-4">
        <div class="text-base text-darkgreen uppercase font-bold mb-4">
          {{ "BusinessUnit.Title" | translate }}
        </div>
        <div class="flex flex-col -ml-3">
          <mat-radio-group
            color="accent"
            class="flex flex-col"
            name="businessUnit"
            [(ngModel)]="businessUnitModel"
            (ngModelChange)="onBusinessUnitChange($event)"
          >
            <mat-radio-button
              *ngFor="let item of businessUnits"
              [value]="item.id"
            >
              {{ item.displayName || item.name }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div
          class="p-4 text-center bg-default bg-opacity-50 rounded-md text-sm"
          *ngIf="businessUnits.length <= 0"
        >
          {{ "Services.NoData" | translate }}
        </div>
      </div>
    </ng-template>
  </mat-menu>
  <!-- <a
  class="flex flex-col gap-1 justify-center items-center h-full p-2 w-full overflow-hidden bg-grey-600 rounded-md text-center transition hover:bg-grey-500 text-white no-underline"
  routerLink="/management"
  queryParamsHandling="preserve"
>
  <mat-icon
    [svgIcon]="'mat_outline:book'"
    [routerLinkActive]="'text-warn'"
  ></mat-icon>
  <div class="uppercase truncate w-full text-xs">
    {{ "Navigation.Management" | translate }}
  </div>
</a> -->
  <a
    class="flex flex-col gap-1 justify-center items-center h-full p-2 w-full overflow-hidden bg-grey-600 rounded-md text-center transition hover:bg-grey-500 text-white no-underline"
    routerLink="/settings"
    queryParamsHandling="preserve"
  >
    <mat-icon
      [svgIcon]="'design:setting'"
      [routerLinkActive]="'text-warn'"
    ></mat-icon>
    <div class="uppercase truncate w-full text-xs">
      {{ "Navigation.Settings" | translate }}
    </div>
  </a>
  <a
    class="flex flex-col gap-1 justify-center items-center h-full p-2 w-full overflow-hidden bg-grey-600 rounded-md text-center transition hover:bg-grey-500 text-white no-underline"
    routerLink="/profile"
    queryParamsHandling="preserve"
  >
    <mat-icon
      [svgIcon]="'mat_outline:account_circle'"
      [routerLinkActive]="'text-warn'"
    ></mat-icon>
    <div class="uppercase truncate w-full text-xs">
      {{ "Profile.AccountInfo" | translate }}
    </div>
  </a>
  <button
    class="flex flex-col gap-1 justify-center items-center h-full p-2 w-full overflow-hidden bg-grey-600 rounded-md text-center transition text-white"
    type="button"
    [matMenuTriggerFor]="seeMoreMenu"
  >
    <mat-icon [svgIcon]="'mat_outline:more_horiz'"></mat-icon>
    <div class="uppercase truncate w-full text-xs">
      {{ "Action.SeeMore" | translate }}
    </div>
  </button>

  <mat-menu #seeMoreMenu="matMenu">
    <ng-template matMenuContent>
      <div class="flex flex-col gap-2">
        <div class="flex flex-col gap-2" *ngFor="let group of navigation">
          <ng-container *ngFor="let item of group.children">
            <ng-container *ngIf="!item.externalLink">
              <button
                mat-menu-item
                type="button"
                queryParamsHandling="preserve"
                [ngClass]="
                  item.disabled ||
                  (item.id != 'AC' &&
                    item.id != 'DC' &&
                    item.id != 'SC' &&
                    checkDisabled())
                    ? '!cursor-not-allowed opacity-60'
                    : 'cursor-pointer opacity-100'
                "
                [routerLink]="
                  item.disabled ||
                  (item.id != 'AC' &&
                    item.id != 'DC' &&
                    item.id != 'SC' &&
                    checkDisabled())
                    ? null
                    : item.link
                "
                *hasResource="item.id == 'SM' ? item.id : ''"
                class="flex items-center gap-2"
              >
                <mat-icon
                  [routerLinkActive]="'text-warn'"
                  [svgIcon]="item.icon ?? ''"
                  [ngClass]="item.classes?.icon"
                ></mat-icon>
                <div class="text-sm" [routerLinkActive]="'text-warn'">
                  {{ item.title || "" | translate }}
                </div>
              </button>
            </ng-container>

            <ng-container *ngIf="item.externalLink">
              <a
                mat-menu-item
                [ngClass]="
                  item.disabled
                    ? '!cursor-not-allowed opacity-60'
                    : 'cursor-pointer opacity-100 '
                "
                class="flex items-center gap-2 no-underline"
                href="{{ item.link }}"
                target="{{ item.target }}"
              >
                <mat-icon
                  [routerLinkActive]="'text-warn'"
                  [svgIcon]="item.icon ?? ''"
                  [ngClass]="item.classes?.icon"
                ></mat-icon>
                <div class="text-sm" [routerLinkActive]="'text-warn'">
                  {{ item.title || "" | translate }}
                </div>
              </a>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </mat-menu>
</div>
