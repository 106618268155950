<div class="relative flex w-full h-full">
  <div class="flex flex-auto w-full flex-col overflow-hidden">
    <div
      class="flex gap-4 px-4 md:px-5 h-14 items-center shadow bg-surface flex-shrink-0 z-10 relative"
    >
      <div class="font-bold uppercase text-xl text-accent">ERP</div>
      <app-languages class="ml-auto"></app-languages>
      <div class="flex items-center gap-4">
        <button mat-icon-button type="button">
          <mat-icon
            class="text-hint"
            [svgIcon]="'mat_outline:notifications'"
          ></mat-icon>
        </button>
        <button mat-button type="button">
          <div class="flex items-center gap-1">
            {{ "Navigation.Help" | translate }}
            <mat-icon
              class="text-hint icon-size-5"
              [svgIcon]="'mat_outline:keyboard_arrow_down'"
            ></mat-icon>
          </div>
        </button>
      </div>
    </div>
    <div class="flex-grow overflow-auto">
      <div class="mx-auto h-full">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <sidebar [navigation]="navigation" class="flex-shrink-0"></sidebar>
</div>
