<loading-bar></loading-bar>
<div class="flex flex-col h-full">
  <div class="flex-grow overflow-hidden">
    <admin-layout *ngIf="layout === 'admin'"></admin-layout>

    <div class="w-full h-full overflow-hidden" *ngIf="layout !== 'admin'">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-horizontal-sidebar
    [navigation]="navigation"
    *ngIf="isScreenSmall && isAuthenticated"
    class="flex-shrink-0"
  ></app-horizontal-sidebar>
</div>
