import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { noAuthGuard } from './core/auth/guards/noAuth.guard';
import { authGuard } from './core/auth/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    canLoad: [noAuthGuard],
    canActivate: [noAuthGuard],
    canActivateChild: [noAuthGuard],
    children: [
      {
        path: 'login',
        data: {
          title: 'Đăng nhập',
        },
        loadComponent: () =>
          import('./modules/auth/login/login.component').then(
            (c) => c.LoginComponent
          ),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canLoad: [authGuard],
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    children: [
      {
        path: 'management',
        data: {
          title: 'Navigation.Management',
          layout: 'admin',
        },
        loadChildren: () =>
          import('./modules/management/management.routes').then(
            (m) => m.routes
          ),
      },
      {
        path: 'sale-management',
        loadChildren: () =>
          import('./modules/sale-management/sale-management.routes').then(
            (m) => m.routes
          ),
      },
      {
        path: 'crm',
        data: {
          title: 'CRM.Title',
          layout: 'admin',
        },
        loadChildren: () =>
          import('./modules/crm/crm.routes').then((m) => m.routes),
      },
      {
        path: 'supply-chain-management',
        data: {
          title: 'Navigation.SupplyChainManagement',
          layout: 'admin',
        },
        loadChildren: () =>
          import(
            './modules/supply-chain-management/supply-chain-management.routes'
          ).then((m) => m.routes),
      },
      {
        path: 'manufacture-management',
        data: {
          title: 'ManufactureManagement.Title',
          layout: 'admin',
        },
        loadChildren: () =>
          import(
            './modules/manufacture-management/manufacture-management.routes'
          ).then((c) => c.routes),
      },
      {
        path: 'profile',
        data: {
          title: 'Profile.AccountInfo',
          layout: 'admin',
        },
        loadComponent: () =>
          import('./modules/profile/profile.component').then(
            (m) => m.ProfileComponent
          ),
      },
      {
        path: 'account-management',
        data: {
          title: 'AccountManagement.Title',
          layout: 'admin',
        },
        loadChildren: () =>
          import('./modules/account-management/account-management.routes').then(
            (m) => m.routes
          ),
      },
      {
        path: 'warehouse-management',
        data: {
          title: 'WarehouseManagement.Title',
          layout: 'admin',
        },
        loadChildren: () =>
          import(
            './modules/warehouse-management/warehouse-management.routes'
          ).then((m) => m.routes),
      },
      {
        path: 'settings',
        data: {
          title: 'SettingAndConfiguration.Title',
          layout: 'admin',
        },
        loadChildren: () =>
          import('./modules/settings/settings.routes').then((c) => c.routes),
      },

      {
        path: 'employee',
        data: {
          title: 'Employee.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/employee/employee.component').then(
            (m) => m.EmployeeComponent
          ),
      },
      {
        path: 'employee/:id',
        data: {
          title: 'Employee.EmployeeDetail',
          layout: 'blank',
        },
        loadComponent: () =>
          import(
            './modules/employee/employee-detail/employee-detail.component'
          ).then((m) => m.EmployeeDetailComponent),
      },
    ],
  },
  {
    path: 'not-authorized',
    data: {
      title: 'NotFound.NotAuth',
      layout: 'blank',
    },
    loadComponent: () =>
      import('./modules/not-authorized/not-authorized.component').then(
        (m) => m.NotAuthorizedComponent
      ),
  },
  {
    path: 'not-found',
    data: {
      title: 'NotFound.PageNotFound',
      layout: 'blank',
    },
    loadComponent: () =>
      import('./modules/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full',
  },
];
