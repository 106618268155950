{
  "Common": {
    "FromList": "từ danh sách",
    "Max": "Tối đa"
  },
  "Auth": {
    "Common": {
      "Password": "mật khẩu"
    },
    "Errors": {
      "UsernameOrPassword": "Tên đăng nhập hoặc mật khẩu không đúng",
      "LoginAgain": "Vui lòng đăng nhập lại"
    },
    "Login": {
      "Title": "Đăng nhập",
      "EmailPlaceholder": "Nhập email",
      "PasswordPlaceholder": "Nhập mật khẩu",
      "Heading": "Trải nghiệm <br /> cùng <span class='text-3xl text-default'>hmbotanic</span>"
    },
    "Register": {
      "Title": "đăng kí"
    },
    "ForgotPassword": {
      "Title": "quên mật khẩu"
    }
  },
  "Confirmation": {
    "SaveChange": "Bạn có muốn lưu thay đổi chưa được lưu không?",
    "Cancel": "Hủy",
    "Confirm": "Xác nhận",
    "Title": "Xác nhận"
  },
  "NotFound": {
    "PageNotFound": "Không tìm thấy thông tin hoặc trang này",
    "NotAuth": "Bạn không có quyền truy cập trang này. Liên hệ admin để được trợ giúp"
  },
  "Status": {
    "All": "Tất cả",
    "Active": "Hoạt động",
    "Inactive": "Khóa"
  },
  "Errors": {
    "Required": "Trường này là bắt buộc",
    "InvalidEmail": "Email không hợp lệ",
    "InvalidPhone": "Số điện thoại không hợp lệ",
    "MustMatch": "{{control}} phải trùng với {{matchingControl}}",
    "MinLength": "Must be at least {{minLength}} characters.",
    "ExistingCode": "Mã này đã được sử dụng",
    "InvalidAddress": "Địa chỉ phải được chọn từ google map",
    "InvalidValue": "Giá trị không đúng",
    "AutoComplete": "Trường này phải chọn từ danh sách",
    "Whitespace": "Không thể chỉ chứa khoảng trắng",
    "Unauthorized": "Tài khoản hiện tại không có quyền thực hiện thao tác này, vui lòng liên hệ Admin để cấp quyền",
    "UserNotExists": "Người dùng không tồn tại",
    "PasswordNotCorrect": "Mật khẩu không chính xác",
    "UserAlreadyExists": "Người dùng đã tồn tại",
    "DataNotExists": "Dữ liệu không tồn tại",
    "DataInputInvalid": "Dữ liệu nhập vào không hợp lệ",
    "InvoiceExists": "Hóa đơn đã được tạo trong đơn hàng, vui lòng kiểm tra lại",
    "UploadFileError": "Tập tin tải lên bị lỗi",
    "EnumInvalidOrNotDefined": "Giá trị không hợp lệ. Vui lòng kiểm tra và nhập lại",
    "OrderCannotDelete": "Đơn hàng không thể xóa. Vui lòng kiểm tra lại",
    "OrderNotValidToAdjustment": "Đơn hàng không thể điều chỉnh. Vui lòng kiểm tra lại",
    "WarehouseNoteNotValidToProcess": "Chứng từ kho không xử lý thành công. Vui lòng kiểm tra lại",
    "SomethingWentWrong": "Đã có lỗi xảy ra",
    "Retry": "Đã có lỗi xảy ra. Vui lòng thử lại"
  },
  "Pagination": {
    "Display": "Hiển thị",
    "Page": "Trang",
    "TotalRecords": "Tổng <span class='font-bold text-accent'>{{value}}</span> bản ghi"
  },
  "Action": {
    "Title": "Thao tác",
    "Lock": "Khóa",
    "Unlock": "Mở khóa",
    "Delete": "Xóa",
    "Edit": "Sửa",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Reset": "Đặt lại",
    "Save": "Lưu",
    "AddPrices": "Thêm giá mới",
    "SelectImage": "Chọn hình",
    "Apply": "Áp dụng",
    "Next": "Kế tiếp",
    "Select": "Chọn",
    "Cancel": "Hủy",
    "Create": "Tạo mới",
    "ViewDetail": "Xem chi tiết",
    "Confirm": "Xác nhận",
    "Search": "Tìm kiếm",
    "Enter": "Nhập",
    "Close": "Đóng",
    "View": "Xem",
    "Remove": "Loại bỏ",
    "Approve": "Xác nhận",
    "Complete": "Hoàn thành",
    "Import": "Nhập kho",
    "Export": "Xuất kho",
    "SelectAll": "Chọn tất cả",
    "Print": "In",
    "Change": "Thay đổi",
    "SeeMore": "Xem thêm",
    "Upload": "Tải lên",
    "Retry": "Thử lại",
    "Option": "Tùy chọn",
    "Deliver": "Giao hàng",
    "Adjust": "Điều chỉnh",
    "InProgress": "Thực thi"
  },
  "Placeholder": {
    "Enter": "Nhập {{value}}",
    "Select": "Chọn {{value}}"
  },
  "Services": {
    "ChangeStatusSuccess": "Thay đổi trạng thái thành công",
    "DeleteConfirm": "Bạn có chắc muốn xóa {{title}} này?",
    "DeleteConfirmDefault": "Bạn có chắc muốn xóa?",
    "DeleteSuccess": "Xóa {{title}} thành công",
    "DeleteSuccessDefault": "Xóa thành công",
    "RemoveConfirmDefault": "Bạn có chắc muốn loại bỏ?",
    "RemoveSuccess": "Loại bỏ {{title}} thành công",
    "RemoveSuccessDefault": "Loại bỏ thành công",
    "CreateNewSuccess": "Tạo mới {{title}} thành công",
    "CreateNewSuccessDefault": "Tạo mới thành công",
    "UpdateSuccess": "Cập nhật {{title}} thành công",
    "UpdateSuccessDefault": "Cập nhật thành công",
    "NoData": "Không có bản ghi",
    "NoInfo": "Không có thông tin",
    "SaveSuccessDefault": "Lưu thành công",
    "CancelConfirmDefault": "Bạn có chắc muốn hủy?",
    "CancelSuccessDefault": "Hủy thành công",
    "ConfirmDefault": "Bạn có chắc muốn thực hiện hành động này?",
    "PleaseSelectBusinessUnit": "Vui lòng chọn đơn vị kinh doanh"
  },
  "Button": {
    "SignIn": "Đăng nhập",
    "Confirm": "Xác nhận",
    "Cancel": "Hủy",
    "Delete": "Xóa",
    "Logout": "Đăng xuất",
    "Back": "Quay lại",
    "HomePage": "Trang chủ"
  },
  "SettingAndConfiguration": {
    "Category": "Danh mục",
    "Profile": "Thông tin cá nhân"
  },
  "Category": {
    "SellingAndBuying": "Selling and buying",
    "Warehouse": "Warehouse",
    "Organization": "Organization",
    "Customer": "Customer",
    "Document": "Document",
    "Logistic": "Logistic"
  },
  "Profile": {
    "Title": "Thông tin cá nhân",
    "GeneralInformation": "Thông tin chung",
    "LoginDetail": "Thông tin đăng nhập",
    "Name": "Tên",
    "Email": "Email",
    "PhoneNumber": "Số điện thoại",
    "UserName": "Tên người dùng",
    "CurrentPassword": "Mật khẩu hiện tại",
    "Password": "Mật khẩu",
    "ConfirmPassword": "Xác nhận mật khẩu",
    "AccountInfo": "Thông tin tài khoản"
  },
  "Navigation": {
    "SalesManagement": "Quản lý bán hàng",
    "WarehouseManagement": "Quản lý kho",
    "Logistics": "Quản lý giao nhận",
    "FinancialManagement": "Quản lý kế toán",
    "CRM": "CRM",
    "SupplyChainManagement": "Quản lý chuỗi cung ứng",
    "AccountManagement": "Quản lý tài khoản",
    "SettingAndConfiguration": "Quản lý và thiết lập",
    "ManufacturingManagement": "Quản lý sản xuất",
    "Help": "Trợ giúp",
    "Settings": "Thiết lập",
    "Management": "Quản lý",
    "Documentation": "Tài liệu"
  },
  "Bank": {
    "Title": "Ngân hàng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "NewBank": "Ngân hàng mới",
    "BankUpdate": "Cập nhật ngân hàng"
  },
  "PointOfSale": {
    "Title": "Điểm bán hàng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "List": "Danh sách",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "Hotline": "Hotline",
    "Address": "Địa chỉ",
    "DisplayName": "Tên hiển thị",
    "PhoneNumber": "Số điện thoại",
    "IsActive": "Trạng thái",
    "NewPointOfSale": "Điểm bán hàng mới",
    "UpdatePointOfSale": "Cập nhật điểm bán hàng",
    "IsOnline": "Is online",
    "BusinessType": "Loại hình kinh doanh",
    "BusinessUnit": "Đơn vị kinh doanh",
    "SelectPointOfSale": "Chọn điểm bán hàng"
  },
  "Product": {
    "Title": "Sản phẩm",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewProduct": "Sản phẩm mới",
    "ProductDetail": "Chi tiết sản phẩm",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewProduct": "Thêm sản phẩm mới",
    "UpdateProduct": "Cập nhật sản phẩm",
    "EnterProductName": "Enter product name",
    "EnterProductCode": "Enter product code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "OfficialName": "Official name",
    "VatName": "Tên VAT",
    "Note": "Ghi chú",
    "Region": "Region",
    "Tags": "Tags",
    "Price": "Giá",
    "Quantity": "Số lượng",
    "Weight": "Trọng lượng",
    "ConvertedQuantity": "Converted quantity",
    "GeneralInfo": "Thông tin chung",
    "IsHotProduct": "Is hot product",
    "IsNewProduct": "Is new product",
    "IsRecommendedProduct": "Is recommended product",
    "Wholesale": "Giá sỉ",
    "Retail": "Giá lẻ",
    "SelectCatalogToSeeProducts": "Vui lòng chọn catalog",
    "NoProduct": "Không có sản phẩm",
    "PleaseSelectProduct": "Vui lòng chọn sản phẩm",
    "QuantityByUnit": "Quantity by unit",
    "PurchaseOrderQuantity": "Purchase order quantity",
    "MinStockQuantityWarning": "Min stock quantity warning",
    "GrossWeight": "Gross weight",
    "NetWeight": "Net weight",
    "Measurement": "Measurement",
    "IsBundle": "Is bundle",
    "IsPackage": "Is package",
    "IsInventory": "Is inventory",
    "IsCommission": "Is commission",
    "IsWarningInventory": "Is warning inventory",
    "IsPurchaseOrderSupplier": "Is purchase order supplier",
    "ProductOrigin": "Product origin",
    "ProductStockSetting": "Thiết lập tồn",
    "ProductOrderSetting": "Thiết lập đặt hàng",
    "ListPrice": "List price",
    "OriginalPrice": "Original price",
    "NoPrice": "Chưa có giá",
    "ProductType": "Loại hàng",
    "ProductList": "Danh sách sản phẩm",
    "SelectProduct": "Chọn sản phẩm"
  },
  "Unit": {
    "Title": "Đơn vị tính",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "UnitType": "Thiết lập",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Trang",
    "TotalRecords": "Tổng {{value}} bản ghi",
    "NewUnit": "Đơn vị tính mới",
    "UnitDetail": "Chi tiết đơn vị tính",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Save": "Lưu",
    "Update": "Update",
    "AddNewUnit": "Thêm mới đơn vị tính",
    "UnitUpdate": "Cập nhật đơn vị tính",
    "EnterUnitName": "Nhập tên",
    "EnterUnitCode": "Nhập mã",
    "EnterDisplayName": "Nhập tên hiển thị",
    "EnterDescription": "Nhập mô tả"
  },
  "Sale": {
    "Title": "Bán hàng",
    "NewOrder": "Đơn hàng mới",
    "NewOrderTicket": "Phiếu đặt hàng",
    "RetailOrder": "Đơn hàng lẻ",
    "WholesaleOrder": "Đơn hàng sỉ",
    "SalesManagement": "Quản lý bán hàng",
    "TableManaGeMent": "Quản lý bàn",
    "OrderToday": "Đơn hàng hôm nay",
    "PurchaseOrder": "Đơn hàng",
    "NewOnlineOrder": "Đơn hàng online mới",
    "Website": "Website",
    "MobileApp": "Mobile app",
    "Invoice": "Hóa đơn"
  },
  "Brand": {
    "Title": "Nhãn hàng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Add": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "NewBrand": "Nhãn hàng mới",
    "UpdateBrand": "Cập nhật nhãn hàng"
  },
  "Catalog": {
    "Title": "Catalog",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Add": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "NewCatalog": "Catalog mới",
    "UpdateCatalog": "Cập nhật catalog",
    "All": "Tất cả",
    "EditCatalog": "Chỉnh sửa catalog",
    "NoCatalog": "Chưa có catalog"
  },
  "BusinessType": {
    "Title": "Loại hình kinh doanh",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Add": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "NewBusinessType": "Loại hình kinh doanh mới",
    "UpdateBusinessType": "Cập nhật loại hình kinh doanh"
  },
  "Department": {
    "Title": "Bộ phận",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewDepartment": "Bộ phận mới",
    "UpdateDepartment": "Cập nhật bộ phận"
  },
  "Position": {
    "Title": "Chức vụ",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewPosition": "Chức vụ mới",
    "UpdatePosition": "Cập nhật chức vụ"
  },
  "Region": {
    "Title": "Khu vực",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewRegion": "Khu vực mới",
    "UpdateRegion": "Cập nhật khu vực",
    "ParentRegion": "Khu vực cha",
    "SelectParentRegion": "Chọn khu vực cha"
  },
  "Warehouse": {
    "Title": "Kho",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewWarehouse": "Kho mới",
    "UpdateWarehouse": "Cập nhật kho",
    "IsInternalWarehouse": "Là kho nội bộ",
    "PhoneNumber": "Số điện thoại",
    "DisplayAddress": "Địa chỉ hiển thị",
    "BusinessUnit": "Đơn vị kinh doanh",
    "Address": "Địa chỉ",
    "WarehouseType": "Loại kho",
    "IsAllowExternalImport": "Is allow external import",
    "IsAllowExternalExport": "Is allow external export",
    "IsAllowExternalTransfer": "Is allow external transfer",
    "IsSpecificSettingTransfer": "Is specific setting transfer"
  },
  "WarehouseType": {
    "Title": "Loại kho",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewWarehouseType": "Loại kho mới",
    "UpdateWarehouseType": "Cập nhật loại kho",
    "Production": "Kho linh kiện, bộ linh kiện sản xuất",
    "FinishedGoods": "Kho thành phẩm",
    "Commercial": "Kho bán hàng, thương mại",
    "Tool": "Kho công cụ dụng cụ",
    "RawMaterial": "Kho nguyên vật liệu",
    "Asset": "Kho tài sản cố định",
    "Scrapyard": "Kho phế liệu",
    "Distribution": "Kho phân phối, trung chuyển",
    "Other": "Kho khác"
  },
  "Address": {
    "Title": "Địa chỉ",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewAddress": "Địa chỉ mới",
    "UpdateAddress": "Cập nhật địa chỉ",
    "AddressText": "Địa chỉ",
    "AddressType": "Loại địa chỉ",
    "MapLocation": "Map location",
    "Street": "Đường",
    "District": "Quận",
    "City": "Thành phố",
    "State": "State",
    "AddressName": "Tên địa chỉ",
    "Note": "Ghi chú",
    "Country": "Quốc gia",
    "lat": "Lat",
    "Lng": "Lng",
    "SelectAddressType": "Chọn loại địa chỉ"
  },
  "ProductType": {
    "Title": "Loại sản phẩm",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewProductType": "Loại sản phẩm mới",
    "UpdateProductType": "Cập nhật loại sản phẩm"
  },
  "Currency": {
    "Title": "Tiền tệ",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "NewCurrency": "Tiền tệ mới",
    "UpdateCurrency": "Cập nhật tiền tệ"
  },
  "BusinessGroup": {
    "Title": "Nhóm khách hàng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "NewBusinessGroup": "Nhóm khách hàng mới",
    "UpdateBusinessGroup": "Cập nhật nhóm khách hàng",
    "BusinessGroupType": "Loại nhóm khách hàng"
  },
  "BusinessGroupType": {
    "Customer": "Khách hàng",
    "Supplier": "Nhà cung cấp",
    "InternalAgency": "Cửa hàng, đại lý (nội bộ)",
    "FranchiseAgency": "Cửa hàng, đại lý (nhượng quyền)",
    "ExternalAgency": "Cửa hàng, đại lý (bên ngoài)",
    "InternalLogistic": "Đơn vị vận chuyển (nội bộ)",
    "ExternalLogistic": "Đơn vị vận chuyển (bên ngoài)",
    "Other": "Khác"
  },
  "Rank": {
    "Title": "Hạng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "NewRank": "Hạng mới",
    "UpdateRank": "Cập nhật hạng",
    "RankType": "Loại",
    "SelectRankType": "Chọn loại hạng"
  },
  "RankType": {
    "Business": "Hạng khách hàng",
    "Product": "Hạng sản phẩm",
    "Service": "Hạng dịch vụ",
    "Other": "Hạng khác"
  },
  "PaymentSchedule": {
    "All": "Tất cả các ngày",
    "ThreeTimesInMonth": "1, 10, 20 hàng tháng",
    "Weekend": "T7, CN cuối tuần",
    "FiveTimesInMonth": "5, 10, 15, 20, 25 hàng tháng",
    "SpecificDay": "Ngày đặc biệt (cụ thể)"
  },
  "BusinessUnit": {
    "Title": "Đơn vị kinh doanh",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewBusinessUnit": "Đơn vị kinh doanh mới",
    "UpdateBusinessUnit": "Cập nhật đơn vị kinh doanh",
    "IsOffice": "Văn phòng",
    "PhoneNumber": "Số điện thoại",
    "Address": "Địa chỉ",
    "Hotline": "Hotline",
    "DisplayAddress": "Địa chỉ hiển thị",
    "TaxAddress": "Địa chỉ thuế",
    "TaxNumber": "Mã số thuế",
    "Region": "Khu vực",
    "ParentBusinessUnit": "Đơn vị kinh doanh cha",
    "Currency": "Tiền tệ",
    "DecimalDigits": "Số thập phân",
    "All": "Tất cả",
    "SelectParentBusinessUnit": "Chọn đơn vị kinh doanh cha"
  },
  "Office": {
    "Title": "Văn phòng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewOffice": "Văn phòng mới",
    "UpdateOffice": "Cập nhật văn phòng",
    "PhoneNumber": "Số điện thoại",
    "Address": "Địa chỉ",
    "Hotline": "Hotline",
    "DisplayAddress": "Địa chỉ hiển thị",
    "BusinessUnit": "Đơn vị kinh doanh"
  },
  "Price": {
    "Title": "Giá",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "NewPrice": "Giá mới",
    "UpdatePrice": "Cập nhật giá",
    "PriceType": "Loại giá",
    "ProductPrice": "Giá sản phẩm"
  },
  "ProductGroup": {
    "Title": "Nhóm sản phẩm",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewProductGroup": "Nhóm sản phẩm mới",
    "UpdateProductGroup": "Cập nhật nhóm sản phẩm"
  },
  "Country": {
    "Title": "Quốc gia",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "ShortName": "Tên rút gọn",
    "Description": "Mô tả",
    "NumCode": "Mã quốc gia",
    "PhoneCode": "Mã điện thoại",
    "IsActive": "Trạng thái",
    "NewCountry": "Quốc gia mới",
    "UpdateCountry": "Cập nhật quốc gia"
  },
  "AddressType": {
    "BusinessUnitAddress": "Đơn vị kinh doanh",
    "POSAddress": "Điểm bán hàng",
    "WarehouseAddress": "Kho",
    "PlaceOfDelivery": "Địa điểm giao hàng",
    "PlaceOfReceipt": "Địa điểm nhận hàng",
    "CustomerAddress": "Địa chỉ khách hàng",
    "ContactPersonAddress": "Địa chỉ liên hệ",
    "SupplierAddress": "Địa chỉ nhà cung cấp",
    "DistributionAddress": "Địa điểm phân phối",
    "Office": "Địa chỉ văn phòng - tòa nhà",
    "OtherLocation": "Địa điểm khác"
  },
  "Measurement": {
    "OnlyQuantity": "Chỉ số lượng",
    "OnlyWeight": "Chỉ trọng lượng",
    "Both": "Cả số lượng và trọng lượng"
  },
  "ContractType": {
    "Probation": "Thử việc",
    "FullTime": "Toàn thời gian",
    "PartTime": "Bán thời gian",
    "SeasonalStaff": "Thời vụ",
    "Outsourced": "Thuê ngoài"
  },
  "ReasonImportExport": {
    "Add": "Nhập mới",
    "Returned": "Hàng trả về",
    "RelocateTo": "Chuyển kho đến",
    "OtherIncrease": "Tăng khác",
    "RemoveInvoice": "Hủy hóa đơn cộng lại tồn",
    "RejectRelocate": "Từ chối chuyển kho",
    "ConversionProductVariant": "Chuyển đổi sản phẩm - biến thể",
    "ManufactureImport": "Nhập kho sản xuất",
    "RemoveStock": "Hủy hàng",
    "RelocateFrom": "Chuyển kho đi",
    "Gift": "Tặng không ra đơn",
    "InternalUse": "Nội bộ",
    "Sale": "Bán",
    "OtherDecrease": "Giảm khác",
    "ManufactureExport": "Xuất kho sản xuất",
    "StockAdjustment": "Điều chỉnh tồn",
    "WarehouseCheck": "Kiểm kho",
    "ReceivedAdjustment": "Điều chỉnh thực nhận",
    "PackingImport": "Nhập kho đóng gói",
    "PackingExport": "Xuất kho đóng gói"
  },
  "Employee": {
    "Title": "Nhân viên",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Name": "Tên",
    "Code": "Mã",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "NewEmployee": "Nhân viên mới",
    "EmployeeDetail": "Chi tiết nhân viên",
    "Option": "Tùy chọn",
    "FilterByStatus": "Lọc theo trạng thái",
    "FilterByDepartment": "Lọc theo bộ phận",
    "FilterByOffice": "Lọc theo đơn vị công tác",
    "FilterByPosition": "Lọc theo chức vụ",
    "Department": "Bộ phận",
    "Office": "Đơn vị công tác",
    "All": "Tất cả",
    "BasicInfo": "Thông tin cơ bản",
    "Resume": "Lý lịch",
    "ContactAddress": "Địa chỉ liên hệ",
    "OtherInfo": "Thông tin khác",
    "FirstName": "Tên",
    "LastName": "Họ",
    "AliasName": "Bí danh",
    "EmailAddress": "Email",
    "PhoneNumber": "Số điện thoại",
    "ContactPersonName": "Tên người liên hệ",
    "ContactPersonPhone": "Số điện thoại người liên hệ",
    "ContactPersonRelationship": "Quan hệ với người liên hệ",
    "Dob": "Ngày sinh",
    "IdentityNumber": "Số CMND/CCCD",
    "Country": "Quốc gia",
    "Religion": "Tôn giáo",
    "PlaceOfBirth": "Nơi sinh",
    "Domicile": "Nguyên quán",
    "MaritalStatus": "Tình trạng hôn nhân",
    "Education": "Học vấn",
    "Expertise": "Chuyên môn",
    "PermanentAddress": "Địa chỉ thường chú",
    "TempResidenceAddress": "Địa chỉ tạm chú",
    "HamletOrVillage": "Số nhà, đường, thôn,...",
    "WardName": "Phường / Xã",
    "DistrictName": "Quận / Huyện",
    "CityName": "Tỉnh / Thành phố",
    "GeneralInfo": "Thông tin chung",
    "Tax": "Thuế - BHXH",
    "PositionDepartment": "Vị trí - chức vụ",
    "Contract": "Hợp đồng",
    "TaxNumber": "Mã số thuế",
    "SocialInsuranceNumber": "Số BHXH",
    "BankAccountNumber": "Số tài khoản ngân hàng",
    "Bank": "Ngân hàng",
    "DecisionDate": "Ngày thay đổi / bổ nhiệm",
    "Position": "Chức vụ",
    "ContractType": "Loại hợp đồng",
    "ContractStartDate": "Ngày bắt đầu hợp đồng",
    "ContractEndDate": "Ngày kết thúc hợp đồng",
    "ExpiredProbationDate": "Ngày kết thúc thời gian thử việc"
  },
  "Inventory": {
    "Title": "Tồn kho",
    "SearchPlaceholder": "Tìm theo mã hoặc tên của sản phẩm",
    "ProductName": "Sản phẩm",
    "QuantityStock": "Số lượng tồn",
    "WeightStock": "Trọng lượng tồn",
    "IsActive": "Trạng thái",
    "Option": "Tùy chọn",
    "FilterByStatus": "Lọc theo trạng thái",
    "FilterByBusinessUnit": "Lọc theo đơn vị kinh doanh",
    "FilterByWarehouse": "Lọc theo kho",
    "FilterByProductType": "Lọc theo loại sản phẩm",
    "FilterByStock": "Lọc theo tình trạng tồn",
    "InStock": "Còn tồn",
    "OutOfStock": "Không còn tồn",
    "InventoryDetail": "Chi tiết tồn",
    "IncreaseStock": "Tăng tồn",
    "DecreaseStock": "Giảm tồn",
    "AdjustStock": "Điều chỉnh tồn",
    "HistoricalAdjust": "Lịch sử điều chỉnh",
    "Quantity": "Số lượng",
    "Weight": "Trọng lượng",
    "Qty": "SL",
    "Wgt": "TL",
    "ProductType": "Loại",
    "Reason": "Lý do",
    "AddStock": "Nhập kho nhanh",
    "CurrentStock": "Tồn hiện tại",
    "Warehouse": "Kho",
    "InventoryNote": "Chứng từ kho",
    "AddStockSuccessfully": "Nhập kho thành công"
  },
  "HistoricalImportExport": {
    "Title": "Lịch sử điều chỉnh",
    "SearchPlaceholder": "Tìm theo mã hoặc tên của sản phẩm",
    "ProductName": "Sản phẩm",
    "QuantityStock": "Số lượng tồn",
    "WeightStock": "Trọng lượng tồn",
    "IsActive": "Trạng thái",
    "Option": "Tùy chọn",
    "FilterByStatus": "Lọc theo trạng thái",
    "FilterByWarehouse": "Lọc theo kho",
    "FilterByProductType": "Lọc theo loại sản phẩm",
    "FilterByStock": "Lọc theo tình trạng tồn",
    "InStock": "Còn tồn",
    "OutOfStock": "Không còn tồn",
    "InventoryDetail": "Chi tiết tồn",
    "IncreaseStock": "Tăng tồn",
    "DecreaseStock": "Giảm tồn",
    "AdjustStock": "Điều chỉnh tồn",
    "Quantity": "Số lượng",
    "Weight": "Trọng lượng",
    "ProductType": "Loại sản phẩm",
    "Reason": "Lý do",
    "CreatedDate": "Ngày yêu cầu",
    "ModifiedDate": "Thời gian điều chỉnh",
    "Type": "Loại",
    "Increase": "Tăng",
    "Decrease": "Giảm",
    "Adjust": "Điều chỉnh",
    "BeforeChangeQuantity": "SL trước",
    "BeforeChangeWeight": "TL trước",
    "SelectWarehouse": "Chọn kho",
    "SelectProduct": "Chọn sản phẩm",
    "SelectProductType": "Chọn loại sản phẩm",
    "SelectReason": "Chọn lý do",
    "FromDate": "Từ ngày",
    "ToDate": "Đến ngày"
  },
  "Customer": {
    "Title": "Khách hàng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "NewCustomer": "Khách hàng mới",
    "NewCustomerRetail": "Khách hàng lẻ mới",
    "NewCustomerWholesale": "Khách hàng sỉ / đại lý / doanh nghiệp mới",
    "UpdateCustomer": "Cập nhật khách hàng",
    "CustomerDetail": "Chi tiết khách hàng",
    "TotalCustomer": "Tổng khách hàng",
    "TotalRetailCustomer": "Khách lẻ",
    "TotalWholesaleCustomer": "Khách sỉ",
    "Option": "Tùy chọn",
    "Display": "Hiển thị",
    "ShortInfo": "Thông tin rút gọn",
    "FullInfo": "Thông tin đầy đủ",
    "FullInfoSub": "Gồm \"Ngày mua hàng cuối\" và \"Tổng mua\"",
    "FilterByStatus": "Lọc theo trạng thái",
    "FilterByCustomerType": "Lọc theo loại khách hàng",
    "All": "Tất cả",
    "LastOrder": "Đơn hàng cuối",
    "TotalBuy": "Tổng mua",
    "AddToBlackList": "Thêm vào danh sách đen",
    "CustomerType": "Loại khách hàng",
    "SelectCustomer": "Chọn khách hàng",
    "SelectCustomerRetail": "Chọn khách hàng lẻ",
    "SelectCustomerWholesale": "Chọn khách hàng sỉ",
    "CustomerRetail": "Khách hàng lẻ",
    "CustomerWholesale": "Khách hàng sỉ / đại lý / doanh nghiệp",
    "PhoneNumber": "Số điện thoại",
    "Email": "Email",
    "Dob": "Ngày sinh",
    "InvoiceInfo": "Thông tin xuất hóa đơn",
    "BankAccountNumber": "Số tài khoản ngân hàng",
    "TaxNumber": "Mã số thuế",
    "BillingAddress": "Địa chỉ",
    "BusinessContact": "Business contact",
    "DebtSetting": "Thiết lập công nợ",
    "GeneralInfo": "Thông tin chung",
    "DeliveryAddress": "Địa điểm giao nhận hàng",
    "CustomerInfo": "Thông tin khách hàng",
    "RequiredInfo": "Thông tin bắt buộc",
    "OtherInfo": "Thông tin khác",
    "CompanyName": "Tên doanh nghiệp",
    "ContactManager": "Người liên hệ quản lý",
    "ContactPhoneNumber": "Số điện thoại liên hệ",
    "ManagingSetting": "Thiết lập quản lý",
    "ManagingCategorized": "Phân loại quản lý",
    "NewDeliveryAddress": "Địa chỉ giao hàng mới"
  },
  "CustomerType": {
    "Retail": "Khách hàng lẻ",
    "Wholesale": "Khách hàng sỉ",
    "Both": "Khách sỉ & lẻ",
    "Other": "Khác"
  },
  "CustomerStatus": {
    "All": "Tất cả",
    "Active": "Đang hoạt động",
    "Lock": "Khóa",
    "BlackList": "Danh sách đen"
  },
  "OrderType": {
    "Retail": "Đơn hàng lẻ",
    "Wholesale": "Đơn hàng sỉ",
    "Both": "Khác"
  },
  "OrderStatus": {
    "New": "Đơn hàng mới",
    "Approved": "Đã xác nhận",
    "InProgress": "Xuất hóa đơn",
    "ReleaseRequested": "Yêu cầu xuất kho",
    "OnDelivery": "Đang giao hàng",
    "Delivered": "Đã giao hàng",
    "Completed": "Hoàn thành",
    "Cancelled": "Hủy",
    "Expired": "Quá hạn",
    "NewOrder": "Đơn mới",
    "IssuedInvoice": "Đã xuất hóa đơn",
    "Exported": "Đã xuất kho"
  },
  "Order": {
    "Title": "Đơn hàng",
    "SearchPlaceholder": "Tìm theo mã đơn hàng",
    "NewOrder": "Đơn hàng mới",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Status": "Trạng thái",
    "OrderNumber": "Mã đơn hàng",
    "BuyerName": "Tên khách hàng",
    "OrderType": "Loại",
    "OrderTotal": "Tổng đơn hàng",
    "CreatedDate": "Ngày tạo",
    "CreatedPerson": "Người tạo",
    "OrderDetail": "Chi tiết đơn hàng",
    "UpdateOrder": "Cập nhật đơn hàng",
    "CreatedAt": "Tạo lúc",
    "TotalSub": "Tổng tạm tính",
    "TotalOrder": "Tổng đơn hàng",
    "Type": "Loại",
    "SaleInfo": "Thông tin bán hàng",
    "SaleEmployee": "Nhân viên bán hàng",
    "StoreManager": "Quản lý cửa hàng",
    "Seller": "Người bán",
    "CancelOrder": "Hủy đơn hàng",
    "BillInfo": "Thông tin hóa đơn",
    "PaymentMethod": "Phương thức thanh toán",
    "PaymentStatus": "Tình trạng thanh toán",
    "DeliveryInfo": "Thông tin giao hàng",
    "DiscountAmount": "Giảm trừ",
    "DiscountNote": "Ghi chú",
    "TaxAndFee": "Thuế / phí",
    "TaxAndFeeNote": "Ghi chú",
    "ShippingFee": "Phí ship",
    "ShippingFeeNote": "Ghi chú",
    "OrderSubTotal": "Tổng phụ",
    "OrderListSubTotal": "Tổng phụ",
    "CancelConfirm": "Bạn có chắc muốn hủy đơn hàng này?",
    "ResetConfirm": "Bạn có chắc muốn đặt lại đơn hàng?",
    "ExportSale": "Xuất bán",
    "Actual": "Thực tế",
    "Qty": "SL",
    "Wgt": "TL",
    "Quantity": "Số lượng",
    "Weight": "Trọng lượng",
    "SelectCustomer": "Chọn khách hàng",
    "EmtyCart": "Giỏ hàng trống",
    "PleaseAddProducts": "Vui lòng thêm sản phẩm",
    "RemoveCustomerConfirm": "Bạn có chắc muốn xóa khách hàng đã chọn?",
    "AmountOfMoney": "Số tiền",
    "Note": "Ghi chú",
    "PointOfSale": "Cửa hàng / Điểm bán hàng",
    "SellerInfo": "Thông tin người bán",
    "SelectedProduct": "Sản phẩm đã chọn",
    "SelectingProduct": "Sản phẩm đang chọn",
    "UnitPrice": "Đơn giá",
    "ProductType": "Loại sản phẩm",
    "SalePrice": "Giá bán",
    "SaleQuantity": "Số lượng bán",
    "SaleWeight": "Trọng lượng bán",
    "Add": "Thêm",
    "SaleDeliveryInfo": "Thông tin bán hàng và giao hàng",
    "IsBuyAtStore": "Mua tại cửa hàng",
    "OrderUnsaveConfirm": "Đơn hàng sẽ bị xóa, bạn vẫn tiếp tục thao tác?",
    "NoInvoice": "Chưa xuất hóa đơn",
    "Invoice": "Xuất hóa đơn",
    "N/A": "Chưa thiết lập",
    "StockError": "Giá trị phải nhỏ hơn hoặc bằng tồn",
    "IssueInfo": "Thông tin xuất kho",
    "NotIssued": "Chưa xuất kho",
    "CreateIssue": "Tạo phiếu xuất kho",
    "DeliveryAt": "Giao hàng tại",
    "NoDeliveryNote": "Chưa tạo phiếu giao hàng",
    "CreateDeliveryNote": "Tạo phiếu giao hàng",
    "Customer": "Khách hàng",
    "OrderInfo": "Thông tin đơn hàng",
    "CompleteConfirm": "Bạn có chắc muốn hoàn thành đơn hàng?",
    "GrandTotal": "Grand total",
    "RoundAdjustment": "Round adjustment",
    "RoundedTotal": "Rounded total",
    "DisableRoundAdjustment": "Disable round adjustment",
    "TotalTaxAndCharge": "Tổng thuế phí",
    "TotalDiscounts": "Tổng giảm trừ",
    "Discount": "Giảm trừ",
    "DiscountError": "Tổng giảm trừ phải nhỏ hơn hoặc bằng giá trị đơn hàng",
    "SelectOrderType": "Chọn loại đơn hàng",
    "SelectOrderStatus": "Chọn trạng thái đơn hàng",
    "SelectBuyer": "Chọn khách hàng",
    "FromDate": "Từ ngày",
    "ToDate": "Đến ngày",
    "NewOrderItem": "New order item",
    "UpdateOrderItem": "Update order item"
  },
  "Contact": {
    "Title": "Contact",
    "Seller": "Người bán",
    "SelectSeller": "Chọn người bán",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "NewContact": "New contact",
    "UpdateContact": "Update contact",
    "Owner": "Owner",
    "Lead": "Lead",
    "Supplier": "Supplier",
    "Email": "Email",
    "PhoneNumber": "Số điện thoại",
    "Courier": "Đơn vị vận chuyển"
  },
  "BusinessContact": {
    "Title": "Business contact",
    "SearchPlaceholder": "Tìm theo tên",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "PaymentSchedule": "Lịch thanh toán",
    "IsInstantPayment": "Khách hàng tiền liền?",
    "PaymentDayInMonth": "Ngày thanh toán hàng tháng",
    "DebtTermInDay": "Thời hạn công nợ",
    "DebtRoot": "Công nợ đầu kì",
    "IsAllowChangeDebtRoot": "Cho phép cập nhật",
    "DebtAmountLimitation": "Hạn mức công nợ",
    "CustomerSupportAgent": "Customer support agency",
    "NewBusinessContact": "Business contact mới",
    "UpdateBusinessContact": "Cập nhập business contact",
    "SelectBusinessContact": "Chọn business contact",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu"
  },
  "ContactDeliveryAddress": {
    "Title": "Địa điểm giao hàng",
    "Contact": "Contact",
    "Address": "Địa chỉ",
    "ContactPhoneNumber": "Số điện thoại",
    "Name": "Tên",
    "OtherPhoneNumber": "Số điện thoại khác",
    "NoData": "Chưa có địa điểm giao hàng",
    "AddNewAddress": "Thêm địa chỉ mới",
    "ContactPerson": "Người liên hệ / người nhận",
    "NewAddress": "Địa chỉ mới",
    "CreateNewAddress": "Tạo địa chỉ mới?",
    "DeliveryAddress": "Địa chỉ giao hàng",
    "DeliveryAt": "Giao hàng tại"
  },
  "PaymentMethod": {
    "Cash": "Tiền mặt",
    "Cod": "Cash on delivery",
    "BankTransfer": "Chuyển khoản",
    "Debt": "Công nợ",
    "CreditCard": "Credit card",
    "VnPay": "VNPAY",
    "Momo": "MOMO",
    "BalanceAccount": "Tài khoản dư",
    "COP": "Nhờ thu - C.O.P",
    "OpenAccount": "Ghi sổ - Open account",
    "LC": "Tính dụng chứng từ - L.C",
    "Paypal": "Paypal",
    "Other": "Khác"
  },
  "PaymentStatus": {
    "Unpaid": "Chưa thanh toán",
    "PartiallyPaid": "Thanh toán 1 phần",
    "FullyPaid": "Thanh toán đủ"
  },
  "Invoice": {
    "Title": "Hóa đơn",
    "PaymentMethod": "Phương thức thanh toán",
    "SearchPlaceholder": "Tìm theo mã hóa đơn",
    "Stt": "STT",
    "InvoiceNumber": "Mã hóa đơn",
    "InvoiceDate": "Ngày xuất hóa đơn",
    "RemainingAmount": "Chưa thanh toán",
    "PaymentStatus": "Tình trạng thanh toán",
    "InvoiceDetail": "Chi tiết hóa đơn",
    "CreatedPerson": "Người xuất hóa đơn",
    "OrderNumber": "Mã đơn hàng",
    "InvoiceInfo": "Thông tin hóa đơn",
    "CustomerInfo": "Thông tin khách hàng",
    "DeliveryInfo": "Thông tin giao hàng",
    "OtherInfo": "Thông tin khác",
    "ProductList": "Danh sách sản phẩm",
    "TotalByUnitPrice": "Tổng tiền theo đơn giá",
    "TotalByActualPrice": "Tổng tiền theo giá thực tế",
    "Discount": "Giảm trừ",
    "TaxFee": "Thuế / Phí",
    "Ship": "Ship",
    "Total": "Tổng",
    "Paid": "Đã thanh toán",
    "Remaining": "Còn lại",
    "ProductName": "Sản phẩm",
    "Type": "Loại",
    "Unit": "ĐVT",
    "Quantity": "Số lượng",
    "Weight": "Trọng lượng",
    "ActualQuantity": "SL thực nhận",
    "ActualWeight": "TL thực nhận",
    "UnitSalePrice": "Đơn giá bán",
    "UnitPrice": "Đơn giá",
    "TotalAmount": "Thành tiền",
    "CancelInvoice": "Hủy hóa đơn",
    "CancelConfirm": "Bạn có chắc muốn hủy hóa đơn này?",
    "Print": "In hóa đơn",
    "PrintWithoutPrice": "In không giá",
    "CancelInvoiceSuccessfully": "Hủy hóa đơn thành công",
    "SelectBuyer": "Chọn khách hàng",
    "SelectPointOfSale": "Chọn điểm bán hàng",
    "FromDate": "Từ ngày",
    "ToDate": "Đến ngày",
    "SelectPaymentMethod": "Chọn phương thức thanh toán",
    "SelectPaymentStatus": "Chọn trạng thái thanh toán"
  },
  "RevisionHistory": {
    "RevisionHistory": "Lịch sử điều chỉnh thực nhận",
    "Adjust": "Điều chỉnh thực nhận",
    "BeforeRevision": "Trước điều chỉnh",
    "AfterRevision": "Sau điều chỉnh",
    "CreatedPerson": "Người tạo",
    "CreatedDate": "Ngày tạo",
    "Confirm": "Xác nhận điều chỉnh"
  },
  "ExportNote": {
    "Title": "Phiếu xuất kho",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo mã",
    "Add": "Thêm phiếu xuất kho",
    "NewExportNote": "Phiếu xuất kho mới",
    "CreateExportNote": "Tạo phiếu xuất kho",
    "ExportNoteDate": "Ngày xuất kho",
    "ExportNotePerson": "Người xuất kho",
    "Code": "Mã phiếu",
    "WarehouseManager": "Thủ kho / Quản lý",
    "Type": "Loại phiếu",
    "Status": "Tình trạng",
    "ClearFilter": "Xóa bộ lọc",
    "Search": "Tìm kiếm",
    "Stt": "Stt",
    "OrderNumber": "Mã đơn hàng",
    "InvoiceNumber": "Mã hóa đơn",
    "Reason": "Lý do",
    "Customer": "Khách hàng",
    "NumberPackage": "Số thùng / kiện",
    "Packager": "Người đóng gói",
    "Supervisor": "Người giám sát ",
    "Operator": "Người xuất kho",
    "Manager": "Người quản lý / thủ kho ",
    "TotalPackage": "Số thùng / kiện",
    "Note": "Ghi chú",
    "Action": "Thao tác",
    "Print": "In phiếu xuất kho",
    "ExportNoteInfo": "Thông tin xuất kho",
    "OrderInfo": "Thông tin đơn hàng",
    "InvoiceInfo": "Thông tin hóa đơn",
    "DeliveryInfo": "Thông tin giao hàng",
    "ProductList": "Danh sách sản phẩm",
    "ProductListToIssue": "Danh sách sản phẩm xuất kho",
    "ProductListInventory": "Danh sách sản phẩm cập nhật tồn",
    "Product": "Sản phẩm",
    "Unit": "ĐVT",
    "Quantity": "Số lượng",
    "Weight": "Trọng lượng",
    "IssueQuantity": "SL xuất",
    "IssueWeight": "TL xuất",
    "ActualQuantity": "SL thực nhận",
    "ActualWeight": "TL thực nhận",
    "InventoryQuantity": "SL tồn",
    "InventoryWeight": "TL tồn",
    "DocumentPrice": "Giá bán",
    "FinalPrice": "Giá kho",
    "Cancel": "Hủy",
    "Issue": "Xuất kho",
    "ReIssue": "Xuất kho lại",
    "CancelConfirm": "Bạn có chắc muốn hủy phiếu xuất kho này?",
    "ExportNoteFromSale": "Phiếu xuất kho hàng bán",
    "Warehouse": "Kho",
    "Invoice": "Hóa đơn",
    "ProductType": "Loại",
    "CreateSuccess": "Tạo phiếu xuất kho thành công",
    "IssueInventoryError": "Số lượng hoặc trọng lượng xuất phải nhỏ hơn hoặc bằng tồn",
    "ExportNoteDetail": "Chi tiết phiếu xuất kho",
    "IssueSuccessfully": "Xuất kho thành công"
  },
  "Delivery": {
    "Title": "Giao nhận",
    "DeliveryInfo": "Thông tin giao hàng",
    "Code": "Mã phiếu",
    "Deliver": "Người giao",
    "NumberPackage": "Số thùng / kiện",
    "Status": "Tình trạng",
    "Buyer": "Bên mua",
    "Seller": "Bên bán"
  },
  "WarehouseNote": {
    "CancelConfirm": "Bạn có chắc muốn hủy phiếu này?",
    "CancelSuccessfully": "Hủy phiếu thành công"
  },
  "WarehouseNoteType": {
    "Receipt": "Phiếu nhập kho",
    "Export": "Phiếu xuất kho",
    "Return": "Phiếu trả hàng",
    "Destruction": "Phiếu hủy hàng",
    "StockAdjustment": "Phiếu điều chỉnh tồn kho",
    "Conversion": "Phiếu chuyển đổi hàng hóa",
    "Stocktaking": "Phiếu kiểm kho",
    "Consumption": "Phiếu xuất kho sử dụng nội bộ",
    "TransferExport": "Phiếu xuất kho từ yêu cầu chuyển kho",
    "TransferReturn": "Phiếu nhập kho hàng trả lại (Rejected) từ yêu cầu chuyển kho",
    "TransferReceipt": "Phiếu nhập kho từ yêu cầu chuyển kho"
  },
  "WarehouseNoteStatus": {
    "New": "Phiếu tạm",
    "Approved": "Phiếu đã được xác nhận",
    "InProgress": "Phiếu đã thực thi",
    "Completed": "Phiếu đã hoàn thành",
    "Cancelled": "Phiếu hủy"
  },
  "TransferNoteStatus": {
    "New": "Phiếu mới",
    "Exported": "Đã xuất chuyển",
    "Imported": "Đã nhập chuyển",
    "Completed": "Thành công",
    "Cancelled": "Hủy bỏ phiếu",
    "Rejected": "Từ chối nhận"
  },
  "UpdateInventoryResult": {
    "Title": "Kết quả cập nhật tồn",
    "Success": "Thành công",
    "Fail": "Thất bại",
    "UpdateAgain": "Cập nhật tồn kho lại"
  },
  "Component": {
    "Title": "Thành phần",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "Sku": "Sku",
    "TrackingUnit": "Tracking unit",
    "PurchaseUnit": "Purchase unit",
    "ConvertPurchaseUnitToTracking": "Convert purchase unit to tracking",
    "ActualCost": "Actual cost",
    "ComponentType": "Loại thành phần",
    "IsActive": "Trạng thái",
    "SelectComponentType": "Chọn loại thành phần",
    "NewComponent": "Thành phần mới",
    "UpdateComponent": "Cập nhập thành phần",
    "ComponentDetail": "Chi tiết thành phần",
    "GeneralInfo": "Thông tin chung",
    "SelectComponent": "Chọn thành phần",
    "IsInventoriable": "Is inventoriable"
  },
  "ComponentType": {
    "All": "Tất cả",
    "AuxiliaryMaterial": "Phụ gia, phụ liệu",
    "Material": "Nguyên vật liệu",
    "Part": "Bộ phận",
    "FinishedProduct": "Thành phẩm",
    "Scrap": "Phế liệu",
    "SurplusProduct": "Phế phẩm",
    "ByProduct": "Sản phẩm phụ"
  },
  "Manufacture": {
    "Title": "Sản xuất",
    "Manufacture": "Sản xuất",
    "Inventory": "Tồn kho",
    "FinishedProduct": "Thành phẩm",
    "Component": "Bộ linh kiện",
    "Material": "Linh kiện",
    "Materials": "Linh kiện",
    "SearchPlaceholder": "Tìm theo mã hoặc tên sản phẩm",
    "Code": "Mã",
    "ManufactureDate": "Ngày sản xuất",
    "Name": "Tên",
    "Quantity": "Số lượng",
    "ActualWeight": "Trọng lượng TT",
    "ActualQuantity": "Số lượng TT",
    "InventoryQuantity": "Số lượng tồn",
    "InventoryWeight": "Trọng lượng tồn",
    "ManufactureQuantity": "Số lượng SX",
    "ManufactureWeight": "Trọng lượng SX",
    "Weight": "Trọng lượng",
    "Unit": "ĐVT",
    "Assignee": "Người được giao",
    "CreatedDate": "Ngày tạo",
    "NewManufacture": "Tạo mới sản xuất",
    "UpdateManufacture": "Cập nhật sản xuất",
    "ManufactureDetail": "Chi tiết sản xuất",
    "ProductMade": "Product made",
    "Recipe": "Recipe",
    "RecipeDescription": "Recipe description",
    "RecipeVersion": "Recipe version",
    "PlannedQuantity": "Số lượng KH",
    "PlannedWeight": "Trọng lượng KH",
    "BatchQuantity": "Batch quantity",
    "BatchWeight": "Batch weight",
    "MaterialName": "Tên nguyên vật liệu",
    "WastageRate": "Tỉ lệ hao hụt",
    "TotalQuantityWastage": "Tổng số lượng tiêu hao",
    "TotalWeightWastage": "Tổng trọng lượng tiêu hao",
    "AdditionalDetail": "Additional detail (optional)",
    "DeadlineDate": "Deadline date",
    "ExpiryDate": "Expiry date",
    "Note": "Ghi chú",
    "ManufactureResult": "Kết quả sản xuất",
    "InventoryUpdateResult": "Kết quả cập nhật tồn kho",
    "Import": "Nhập kho",
    "Export": "Xuất kho",
    "ImportWarehouse": "Kho nhập",
    "ExportWarehouse": "Kho xuất",
    "ByProduct": "Sản phẩm phụ",
    "ByProductName": "Tên sản phẩm phụ",
    "QuantityPerItem": "Số lượng (trên 1 sản phẩm)",
    "WeightPerItem": "Trọng lượng (trên 1 sản phẩm)",
    "FinishedQuantity": "Số lượng TP",
    "FinishedWeight": "Trọng lượng TP",
    "MainProduct": "Sản phẩm chính",
    "PlannedProductionAmount": "Số lượng sản xuất dự kiến",
    "ProductionPlan": "Production plan",
    "Planning": "Kế hoạch",
    "Settings": "Thiết lập",
    "Status": "Trạng thái"
  },
  "ManufactureStatus": {
    "New": "Phiếu tạm",
    "Approved": "Phiếu đã được xác nhận",
    "InProgress": "Phiếu đã thực thi",
    "Finished": "Phiếu đã hoàn tất",
    "Expired": "Phiếu đã quá hạn",
    "Cancelled": "Phiếu hủy",
    "Pending": "Phiếu đang hoãn thực thi"
  },
  "Recipe": {
    "Title": "Công thức",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "ProductComponent": "Product component",
    "Version": "Version",
    "IsActive": "Trạng thái",
    "NewRecipe": "Công thức mới",
    "UpdateRecipe": "Cập nhập công thức",
    "RecipeDetail": "Chi tiết công thức",
    "RecipeItems": "Recipe items",
    "BatchQuantity": "Batch quantity",
    "BatchWeight": "Batch weight"
  },
  "RecipeItem": {
    "NewRecipeItem": "New recipe item",
    "UpdateRecipeItem": "Update recipe item",
    "Component": "Component",
    "ParentComponent": "Parent component",
    "Quantity": "Số lượng",
    "Weight": "Trọng lượng",
    "WastageRate": "Wastage rate",
    "Note": "Ghi chú",
    "Level": "Level"
  },
  "Packing": {
    "Title": "Đóng gói",
    "SearchPlaceholder": "Tìm theo mã hoặc sản phẩm",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã phiếu",
    "Packer": "Người đóng gói",
    "PackageDate": "Ngày đóng gói",
    "PackagingWarehouse": "Kho thành phẩm",
    "ImportWarehouse": "Kho nhập",
    "ProductType": "Loại hàng",
    "PackingItems": "Danh sách đóng gói",
    "FinishedProduct": "Thành phẩm",
    "Product": "Sản phẩm",
    "ImportQuantity": "SL nhập",
    "ImportWeight": "TL nhập",
    "Note": "Ghi chú",
    "PackingProduct": "Đóng gói sản phẩm",
    "PackingDetail": "Chi tiết đóng gói",
    "CreatedDate": "Ngày tạo",
    "IsActive": "Trạng thái",
    "PackageQuantity": "SL đóng gói",
    "PackageWeight": "TL đóng gói",
    "AddAllFinishedProductsInStock": "Thêm toàn bộ thành phẩm còn tồn"
  },
  "PackingStatus": {
    "New": "Phiếu tạm",
    "Approved": "Phiếu đã được xác nhận",
    "Exported": "Phiếu đã xuất kho đóng gói",
    "Imported": "Phiếu đã nhập kho sản phẩm",
    "Completed": "Phiếu đã hoàn thành (kiểm duyệt)",
    "Cancelled": "Phiếu hủy"
  },
  "User": {
    "Title": "Người dùng",
    "SearchPlaceholder": "Tìm theo tên người dùng",
    "Stt": "Stt",
    "UserName": "Tên người dùng",
    "Password": "Mật khẩu",
    "ConfirmPassword": "Xác nhận mật khẩu",
    "FirstName": "Tên",
    "MiddleName": "Tên đệm",
    "LastName": "Họ",
    "AliasName": "Bí danh",
    "Bio": "Bio",
    "PhoneNumber": "Số điện thoại",
    "Dob": "Ngày sinh",
    "Email": "Email",
    "AccountType": "Loại tài khoản",
    "SubscriptionDate": "Ngày đăng kí",
    "SubscriptionExpiredDate": "Ngày hết hạn",
    "IsActive": "Trạng thái",
    "NewButton": "Thêm mới",
    "NewUser": "Tài khoản mới",
    "UpdateUser": "Cập nhật tài khoản",
    "RoleList": "Danh sách vai trò",
    "AddRole": "Thêm vai trò",
    "UserDetail": "Chi tiết người dùng",
    "UserInfo": "Thông tin người dùng",
    "ChangePassword": "Thay đổi mật khẩu"
  },
  "AccountType": {
    "Free": "Tài khoản external: free, trial, guest",
    "Basic": "Tài khoản external: tiêu chuẩn",
    "Premium": "Tài khoản external: cao cấp",
    "Staff": "Tài khoản internal: nhân viên",
    "Manager": "Tài khoản internal: quản lý",
    "Owner": "Tài khoản internal: chủ doanh nghiệp - Business Owner",
    "Admin": "Tài khoản internal: administrator"
  },
  "CRM": {
    "Title": "Quản lý chăm sóc khách hàng"
  },
  "WorkstationType": {
    "Title": "Loại trạm xưởng",
    "NewWorkstationType": "Loại trạm xưởng mới",
    "UpdateWorkstationType": "Cập nhật loại trạm xưởng",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "ElectricityCost": "Electricity cost",
    "RentCost": "Rent cost",
    "ConsumableCost": "Consumable cost",
    "Wages": "Wages",
    "OperatingCosts": "Operating cost",
    "PerHour": "per hour"
  },
  "Workstation": {
    "Title": "Trạm xưởng",
    "NewWorkstation": "Trạm xưởng mới",
    "UpdateWorkstation": "Cập nhật trạm xưởng",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "ElectricityCost": "Electricity cost",
    "RentCost": "Rent cost",
    "ConsumableCost": "Consumable cost",
    "Wages": "Wages",
    "WorkingHours": "Working hours",
    "StartTime": "Start time",
    "EndTime": "End time",
    "OperatingCosts": "Operating cost",
    "PerHour": "per hour",
    "WorkstationDetail": "Chi tiết trạm xưởng",
    "GeneralInfo": "Thông tin chung",
    "NewWorkingHour": "New working hour",
    "UpdateWorkingHour": "Update working hour",
    "TotalWorkingHours": "Total working hours"
  },
  "ManufacturingOperation": {
    "Title": "Operation",
    "NewManufacturingOperation": "Operation mới",
    "UpdateManufacturingOperation": "Cập nhật operation",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "OperationTime": "Operation time",
    "SubOperations": "Sub operations",
    "ParentOperations": "Parent operations",
    "ManufacturingOperationDetail": "Operation detail",
    "GeneralInfo": "Thông tin chung",
    "TotalOperationTime": "Total operation time",
    "SelectOperation": "Select operation",
    "NewSubOperation": "New sub operation",
    "UpdateSubOperation": "Update sub operation",
    "SubOperation": "Sub operation"
  },
  "ManufacturingRouting": {
    "Title": "Routing",
    "NewManufacturingRouting": "Routing mới",
    "UpdateManufacturingRouting": "Cập nhật routing",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "ManufacturingRoutingDetail": "Routing detail",
    "GeneralInfo": "Thông tin chung",
    "SelectRouting": "Select Routing",
    "Sequence": "Sequence",
    "Operations": "Operations"
  },
  "Commodity": {
    "Title": "Hàng hóa",
    "NewCommodity": "Hàng hóa mới",
    "UpdateCommodity": "Cập nhật hàng hóa",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "SelectCommodityGroup": "Chọn nhóm hàng hóa"
  },
  "InspectionParameterGroup": {
    "Title": "Nhóm thông số kiểm định",
    "NewInspectionParameterGroup": "Nhóm thông số kiểm định mới",
    "UpdateInspectionParameterGroup": "Cập nhật nhóm thông số kiểm định",
    "SelectInspectionParameter": "Chọn thông số kiểm định",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái"
  },
  "InspectionParameter": {
    "Title": "Thông số kiểm định",
    "NewInspectionParameter": "Thông số kiểm định mới",
    "UpdateInspectionParameter": "Cập nhật thông số kiểm định",
    "SelectInspectionParameter": "Chọn thông số kiểm định",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái"
  },
  "LocationType": {
    "Title": "Location type",
    "NewLocationType": "New location type",
    "UpdateLocationType": "Update location type",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái"
  },
  "LocationProfile": {
    "Title": "Location profile",
    "NewLocationProfile": "New location profile",
    "UpdateLocationProfile": "Update location profile",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "StorageUnit": "Storage unit",
    "LocationFormat": "Location format",
    "IsAllowMixedItems": "Is allow mixed items",
    "IsAllowMixedProductType": "Is allow mixed product type",
    "IsAllowStorageMultiItems": "Is allow storage multiple items",
    "ActualLocationWidth": "Actual location width",
    "ActualLocationHeight": "Actual location height",
    "ActualLocationDepth": "Actual location depth",
    "ActualLocationVolume": "Actual location volume",
    "UsableLocationWidth": "Usable location width",
    "UsableLocationHeight": "Usable location height",
    "UsableLocationDepth": "Usable location depth",
    "UsableLocationVolume": "Actual location volume",
    "MaximumWeight": "Maximum weight"
  },
  "LocationFormat": {
    "UniqueCode": "Mã duy nhất",
    "AisleRackShelfBin": "Mã sinh theo Aisle - Rack - Shelf - Bin"
  },
  "Zone": {
    "Title": "Zone",
    "NewZone": "New zone",
    "UpdateZone": "Update zone",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái"
  },
  "ZoneGroup": {
    "Title": "Zone group",
    "NewZoneGroup": "New zone group",
    "UpdateZoneGroup": "Update zone group",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái"
  },
  "CommodityGroup": {
    "Title": "Nhóm hàng hóa",
    "NewCommodityGroup": "Nhóm hàng hóa mới",
    "UpdateCommodityGroup": "Cập nhật nhóm hàng hóa",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái"
  },
  "DeliveryNote": {
    "Title": "Phiếu giao hàng",
    "CreateDeliveryNote": "Tạo phiếu giao hàng",
    "DeliveryNoteDetail": "Chi tiết phiếu giao hàng",
    "CancelDeliveryNote": "Hủy phiếu giao hàng",
    "CompleteDeliveryNote": "Hoàn thành giao",
    "Shipper": "Người giao",
    "Consignee": "Người nhận",
    "Buyer": "Khách hàng",
    "Seller": "Người bán",
    "PickupLocation": "Địa chỉ nhận hàng",
    "DeliveryLocation": "Địa chỉ giao hàng",
    "DeliveryDate": "Ngày giao",
    "NumberOfPackages": "Số lượng package",
    "NumberOfPackagesDelivered": "Số lượng package đã giao",
    "PackageDescription": "Mô tả package",
    "Note": "Ghi chú",
    "Shipment": "Vận đơn",
    "ProductImage": "Hình ảnh sản phẩm",
    "DeliveryImage": "Hình ảnh giao hàng",
    "Stt": "STT",
    "DeliveryNoteNumber": "Mã giao hàng",
    "ExpectDeliveryDate": "Ngày giao dự kiến",
    "CreatedDate": "Ngày tạo",
    "SearchPlaceholder": "Tìm theo mã",
    "IsActive": "Trạng thái",
    "CreateSuccessfully": "Tạo phiếu giao hàng thành công",
    "Status": "Trạng thái",
    "ActualDeliveryDate": "Ngày giao hàng thực tế",
    "ConfirmCompleteDeliveryNote": "Xác nhận hoàn thành giao hàng"
  },
  "DeliverySlip": {
    "Title": "Phiếu vận đơn",
    "CreateDeliverySlip": "Tạo phiếu vận đơn",
    "DeliverySlipDetail": "Chi tiết phiếu vận đơn",
    "CancelDeliverySlip": "Hủy phiếu vận đơn",
    "CompleteDeliverySlip": "Hoàn thành phiếu vận đơn",
    "Shipper": "Người giao",
    "Consignee": "Người nhận",
    "Buyer": "Khách hàng",
    "Seller": "Người bán",
    "PickupLocation": "Địa chỉ nhận hàng",
    "DeliveryLocation": "Địa chỉ giao hàng",
    "DeliveryDate": "Ngày giao",
    "NumberOfPackages": "Số lượng package",
    "NumberOfPackagesDelivered": "Số lượng package đã giao",
    "PackageDescription": "Mô tả package",
    "Note": "Ghi chú",
    "Shipment": "Vận đơn",
    "ProductImage": "Hình ảnh sản phẩm",
    "DeliveryImage": "Hình ảnh giao hàng",
    "Stt": "STT",
    "DeliverySlipNumber": "Mã giao hàng",
    "ExpectDeliveryDate": "Ngày giao dự kiến",
    "CreatedDate": "Ngày tạo",
    "SearchPlaceholder": "Tìm theo mã",
    "IsActive": "Trạng thái",
    "CreateSuccessfully": "Tạo phiếu giao hàng thành công",
    "Status": "Trạng thái",
    "ActualDeliveryDate": "Ngày giao hàng thực tế",
    "ConfirmCompleteDeliverySlip": "Xác nhận hoàn thành giao hàng"
  },
  "BillOfLading": {
    "Title": "Vận đơn"
  },
  "DeliveryNoteStatus": {
    "New": "Phiếu mới",
    "Assigned": "Phiếu đã phân phối",
    "InProgress": "Phiếu đang giao",
    "Completed": "Phiếu đã giao",
    "Expired": "Phiếu đã quá hạn",
    "Cancelled": "Phiếu hủy"
  },
  "BankAccount": {
    "Title": "Tài khoản ngân hàng",
    "NewBankAccount": "Tài khoản ngân hàng mới",
    "UpdateBankAccount": "Cập nhật tài khoản ngân hàng",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo số tài khoản",
    "Stt": "STT",
    "BankAccountNumber": "Số tài khoản ngân hàng",
    "Bank": "Ngân hàng",
    "Contact": "Contact",
    "Description": "Mô tả",
    "IsInternalAccount": "Là tài khoản nội bộ",
    "IsActive": "Trạng thái",
    "SelectContact": "Chọn khách hàng",
    "SelectBank": "Chọn ngân hàng"
  },
  "Owner": {
    "Title": "Owner",
    "NewOwner": "New owner",
    "UpdateOwner": "Update owner",
    "OwnerDetail": "Owner detail",
    "DeliveryAddress": "Địa điểm giao nhận hàng",
    "GeneralInfo": "Thông tin chung"
  },
  "Accounting": {
    "Title": "Kế toán"
  },
  "CustomerAccount": {
    "Title": "Tài khoản khách hàng",
    "SearchPlaceholder": "Tìm theo mã tài khoản",
    "NewButton": "Thêm mới",
    "Stt": "STT",
    "Code": "Mã",
    "BusinessContact": "Business contact",
    "Contact": "Contact",
    "Description": "Mô tả",
    "AmountBalance": "Số dư",
    "IsActive": "Trạng thái",
    "NewCustomerAccount": "Tài khoản khách hàng mới",
    "UpdateCustomerAccount": "Cập nhật tài khoản khách hàng",
    "CustomerAccountDetail": "Chi tiết tài khoản khách hàng",
    "CustomerCode": "Mã khách hàng",
    "CustomerName": "Tên khách hàng",
    "PhoneNumber": "Số điện thoại",
    "CustomerType": "Loại khách hàng",
    "HistoricalChanges": "Lịch sử thay đổi",
    "Time": "Thời gian",
    "Reason": "Lý do",
    "Receipt": "Phiếu thu",
    "Change": "Phát sinh",
    "Amount": "Số dư",
    "NoAccount": "Không có",
    "AccountInfo": "Thông tin tài khoản"
  },
  "CustomerDebt": {
    "Title": "Công nợ khách hàng",
    "SearchPlaceholder": "Tìm theo mã khách hàng, tên hoặc số điện thoại",
    "Stt": "STT",
    "Code": "Mã khách hàng",
    "Name": "Tên khách hàng",
    "AmountBalance": "Số dư tài khoản",
    "DebtRoot": "Công nợ đầu kì",
    "Debt": "Công nợ",
    "TotalIncompleteInvoices": "Tổng số đơn nợ",
    "LastReceiptDate": "Ngày thanh toán gần nhất",
    "CustomerDebtDetail": "Chi tiết công nợ",
    "Info": "Thông tin",
    "DisplayName": "Tên hiển thị",
    "PhoneNumber": "Số điện thoại",
    "TotalDebt": "Tổng số công nợ phải thanh toán",
    "CurrentDebt": "Công nợ hiện tại",
    "ExpiredDebt": "Công nợ quá hạn",
    "ExpiredDays": "Số ngày quá hạn",
    "InvoiceList": "Danh sách hóa đơn",
    "CreateReceipt": "Tạo phiếu thu công nợ",
    "TotalInvoiceAmount": "Tổng hóa đơn"
  },
  "Receipt": {
    "Title": "Phiếu thu",
    "NewReceipt": "Phiếu thu mới",
    "ReceiptDetail": "Chi tiết phiếu thu",
    "SearchPlaceholder": "Tìm theo mã phiếu thu hoặc tên người nộp",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã phiếu",
    "Payer": "Người nộp tiền",
    "Amount": "Số tiền thu",
    "Type": "Loại phiếu",
    "Payee": "Người thu",
    "Status": "Trạng thái",
    "BusinessUnit": "Đơn vị kinh doanh",
    "PayeeOrStore": "Người thu (cửa hàng)",
    "PayerOrCustomer": "Người trả (khách hàng)",
    "CustomerAddress": "Địa chỉ khách hàng",
    "Payment": "Thanh toán",
    "Info": "Thông tin",
    "AccountBalance": "Tài khoản dư",
    "WithdrawFromCustomerAccount": "Trừ tiền từ tài khoản KH",
    "ActualAmount": "Số tiền thu thực tế",
    "TotalAmount": "Tổng thu",
    "PaymentMethod": "Phương thức thanh toán",
    "ReceiptPerson": "Người nhận",
    "EffectiveDate": "Ngày nhận",
    "BankAccount": "Tài khoản ngân hàng",
    "TransactionNumber": "Số giao dịch",
    "Note": "Ghi chú",
    "ReceiptNote": "Nội dung thu",
    "MoneyTaker": "Người đi thu",
    "NewReceiptDebt": "Phiếu thu công nợ mới",
    "NewReceiptDebtRoot": "Phiếu thu công nợ đầu kì mới",
    "NewReceiptAccountBalance": "Phiếu thu tài khoản khách hàng mới",
    "ReceiptDebt": "Phiếu thu công nợ",
    "ReceiptDebtRoot": "Phiếu thu công nợ đầu kì",
    "ReceiptAccountBalance": "Phiếu thu tài khoản khách hàng",
    "CreateReceipt": "Tạo phiếu thu",
    "AddInvoices": "Vui lòng thêm hóa đơn ",
    "Draft": "Phiếu tạm",
    "Collected": "Đã thu",
    "Delete": "Xóa phiếu thu",
    "Complete": "Hoàn thành",
    "Print": "In phiếu thu",
    "CreatedDate": "Ngày tạo",
    "AmountReceivable": "Số tiền phải thu",
    "CollectedAmount": "Số tiền thu",
    "Total": "Tổng",
    "TotalRemainingReceivable": "Tổng số tiền còn lại phải thu",
    "AddLeftOverToCustomerAccount": "Thu dư cộng vào tài khoản KH",
    "Cashier": "Kế toán thu",
    "BalanceAmount": "Số tiền tài khoản dư",
    "DebtRootInfo": "Thông tin công nợ",
    "SelectCustomerToSeeDebtRoot": "Vui lòng chọn khách hàng để xem thông tin công nợ đầu kì",
    "NoDebtRoot": "Khách hàng không có công nợ đầu kì hoặc công nợ đầu kí đang bị khóa",
    "AmountByWords": "Số tiền bằng chữ",
    "PayeeAddress": "Địa chỉ người thu",
    "PayerAddress": "Địa chỉ người trả",
    "FromDate": "Từ ngày",
    "ToDate": "Đến ngày",
    "IsDraft": "Phiếu tạm",
    "FilterByInvoice": "Lọc theo hóa đơn",
    "SelectPaymentReceiptType": "Chọn loại phiếu thu",
    "SelectPaymentReceiptMethod": "Chọn phương thức thanh toán"
  },
  "Payment": {
    "Title": "Phiếu chi",
    "NewPayment": "Phiếu chi mới",
    "PaymentDetail": "Chi tiết phiếu chi",
    "SearchPlaceholder": "Tìm theo mã phiếu chi hoặc tên người chi",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã phiếu",
    "Payer": "Người chi",
    "Amount": "Số tiền",
    "Type": "Loại phiếu",
    "Payee": "Người nhận",
    "Status": "Trạng thái",
    "BusinessUnit": "Đơn vị kinh doanh",
    "PayeeOrStore": "Người nhận (cửa hàng)",
    "PayerOrCustomer": "Người chi (khách hàng)",
    "PayerOrStore": "Người chi (cửa hàng)",
    "PayeeOrCustomer": "Người nhận (khách hàng)",
    "CustomerAddress": "Địa chỉ khách hàng",
    "Payment": "Thanh toán",
    "Info": "Thông tin",
    "AccountBalance": "Tài khoản dư",
    "WithdrawFromCustomerAccount": "Trừ tiền từ tài khoản KH",
    "ActualAmount": "Số tiền chi thực tế",
    "TotalAmount": "Tổng chi",
    "PaymentMethod": "Phương thức thanh toán",
    "ReceiptPerson": "Người nhận",
    "EffectiveDate": "Ngày chi",
    "BankAccount": "Tài khoản ngân hàng",
    "TransactionNumber": "Số giao dịch",
    "Note": "Ghi chú",
    "ReceiptNote": "Nội dung thu",
    "MoneyTaker": "Người đi thu",
    "CreateDraft": "Tạo phiếu tạm",
    "NewReceiptDebt": "Phiếu thu công nợ mới",
    "NewReceiptDebtRoot": "Phiếu thu công nợ đầu kì mới",
    "NewReceiptAccountBalance": "Phiếu thu tài khoản khách hàng mới",
    "ReceiptDebt": "Phiếu thu công nợ",
    "ReceiptDebtRoot": "Phiếu thu công nợ đầu kì",
    "ReceiptAccountBalance": "Phiếu thu tài khoản khách hàng",
    "CreatePayment": "Tạo phiếu chi",
    "AddInvoices": "Vui lòng thêm hóa đơn ",
    "Draft": "Phiếu tạm",
    "Collected": "Đã chi",
    "Delete": "Xóa phiếu chi",
    "Complete": "Hoàn thành",
    "Print": "In phiếu chi",
    "CreatedDate": "Ngày tạo",
    "AmountReceivable": "Số tiền phải chi",
    "CollectedAmount": "Số tiền chi",
    "Total": "Tổng",
    "TotalRemainingReceivable": "Tổng số tiền còn lại phải chi",
    "AddLeftOverToCustomerAccount": "Thu dư cộng vào tài khoản KH",
    "Cashier": "Kế toán chi",
    "BalanceAmount": "Số tiền tài khoản dư",
    "AmountByWords": "Số tiền bằng chữ",
    "PayeeAddress": "Địa chỉ người nhận",
    "PayerAddress": "Địa chỉ người chi",
    "ExternalPayment": "Chi ngoài",
    "InternalPayment": "Chi nội bộ",
    "ReceiverPayment": "Nhân viên nhận tiền",
    "CustomerSupplier": "Khách hàng / Nhà cung cấp",
    "SelectPaymentReceiptType": "Chọn loại phiếu chi",
    "SelectPaymentReceiptMethod": "Chọn phương thức thanh toán",
    "FromDate": "Từ ngày",
    "ToDate": "Đến ngày"
  },
  "PaymentReceiptMethod": {
    "Cash": "Tiền mặt",
    "BankTransfer": "Chuyển khoản",
    "DigitalPayment": "Ví điện tử",
    "AccountBalance": "Use account balance"
  },
  "PaymentReceiptType": {
    "Debt": "Thu công nợ",
    "IncreaseBalance": "Thu tài khoản khách hàng",
    "OpeningBalance": "Thu công nợ đầu kỳ",
    "AdjustIncreaseBalanceAccount": "Điều chỉnh tăng số dư tài khoản khách hàng",
    "Payment": "Chi thường",
    "ReduceBalance": "Chi quyết toán",
    "PaymentDiscount": "Chi quyết toán giảm trừ",
    "AdjustReduceBalanceAccount": "Điều chỉnh tăng số dư tài khoản khách hàng"
  },
  "TransactionReason": {
    "Other": "Lý do không xác định",
    "CreateDebtReceiptFromBalanceAccount": "Tạo phiếu thu công nợ khách hàng có trừ tiền từ tài khoản khách hàng",
    "CreateDebtRootReceiptFromBalanceAccount": "Tạo phiếu thu công nợ đầu kì có trừ tiền từ tài khoản khách hàng",
    "CreateDebtReceiptAddToBalanceAccount": "Tạo phiếu thu công nợ khách hàng có tổng tiền thu lớn hơn tiền phải thu, số dư cộng vào từ tài khoản khách hàng",
    "CreateDebtRootReceiptAddToBalanceAccount": "Tạo phiếu thu công nợ đầu kì có tổng tiền thu lớn hơn tiền phải thu, số dư cộng vào từ tài khoản khách hàng",
    "CreateAccountBalanceReceiptAddToBalanceAccount": "Tạo phiếu thu tài khoản khách hàng",
    "DeleteDebtReceipt": "Xóa phiếu thu công nợ khách hàng",
    "DeleteAccountBalanceReceipt": "Xóa phiếu thu tài khoản khách hàng",
    "DeleteDebtRootReceipt": "Xóa phiếu thu công nợ đầu kì khách hàng",
    "RealReceivedIncrease": "Điều chỉnh thực nhận phát sinh cộng tài khoản khách hàng",
    "RealReceivedDecrease": "Điều chỉnh thực nhận phát sinh trừ tài khoản khách hàng"
  },
  "Reason": {
    "Title": "Lý do",
    "NewReason": "Lý do mới",
    "UpdateReason": "Cập nhật lý do",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "Content": "Nội dung",
    "Description": "Mô tả",
    "Type": "Loại",
    "IsActive": "Trạng thái",
    "SelectReasonType": "Chọn loại lý do"
  },
  "ReasonType": {
    "Return": "Lý do trả hàng",
    "Cancellation": "Lý do hủy hàng",
    "Refund": "Lý do hoàn tiền",
    "Adjustment": "Lý do điều chỉnh",
    "Complaint": "Lý do khiếu nại"
  },
  "ReturnNote": {
    "Title": "Phiếu trả hàng",
    "NewReturnNote": "Phiếu trả hàng mới",
    "CreateReturnNote": "Tạo phiếu trả hàng",
    "UpdateReturnNote": "Cập nhật phiếu trả hàng",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo mã",
    "Stt": "Stt",
    "Code": "Mã phiếu",
    "ReturnNoteDate": "Ngày trả hàng",
    "ImportNoteNumber": "Phiếu nhập kho",
    "InvoiceNumber": "Mã hóa đơn",
    "TotalPackage": "Số thùng / kiện",
    "ReturnRepresentativePerson": "Người trả hàng",
    "ReceiveRepresentativePerson": "Người nhận hàng",
    "Status": "Trạng thái",
    "ReturnNoteInfo": "Thông tin trả hàng",
    "Product": "Sản phẩm",
    "PreQuantity": "SL trước",
    "PreWeight": "TL trước",
    "Quantity": "Số lượng",
    "Weight": "Trọng lượng",
    "ReturnNoteDetail": "Chi tiết phiếu trả hàng",
    "ProductList": "Danh sách sản phẩm",
    "Print": "In phiếu trả hàng",
    "CreateImportNote": "Tạo phiếu nhập kho trả hàng",
    "CreateImportNoteSuccessfully": "Tạo phiếu nhập kho trả hàng thành công",
    "Reason": "Lý do",
    "ReturnDate": "Ngày trả hàng",
    "Note": "Ghi chú",
    "Invoice": "Hóa đơn"
  },
  "ReturnNoteStatus": {
    "New": "Phiếu mới",
    "Imported": "Phiếu đã nhập tồn",
    "Completed": "Phiếu đã hoàn thành",
    "Cancelled": "Phiếu đã hủy",
    "Expired": "Phiếu đã quá hạn"
  },
  "ImportNote": {
    "Title": "Phiếu nhập kho",
    "NewImportNote": "Phiếu nhập kho mới",
    "CreateImportNote": "Tạo phiếu nhập kho",
    "UpdateImportNote": "Cập nhật phiếu nhập kho",
    "ImportNoteDetail": "Chi tiết phiếu nhập kho",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo mã",
    "Stt": "Stt",
    "Code": "Mã phiếu",
    "ImportDate": "Ngày nhập",
    "TotalPackage": "Số thùng / kiện",
    "ReceivePerson": "Người nhận",
    "Supervisor": "Người giám sát ",
    "Operator": "Người nhập kho",
    "Manager": "Người quản lý / thủ kho",
    "Status": "Trạng thái",
    "Type": "Loại phiếu",
    "ImportNoteInfo": "Thông tin nhập kho",
    "ImportNoteDate": "Ngày nhập kho",
    "NumberOfPackages": "Số lượng package",
    "Product": "Sản phẩm",
    "Unit": "ĐVT",
    "Quantity": "Số lượng",
    "Weight": "Trọng lượng",
    "ImportQuantity": "Số lượng nhập",
    "ImportWeight": "Trọng lượng nhập",
    "ProductList": "Danh sách sản phẩm",
    "Print": "In phiếu nhập kho",
    "Reason": "Lý do",
    "ProductToImport": "Sản phẩm nhập kho",
    "Note": "Ghi chú",
    "ConfirmImport": "Xác nhận nhập kho"
  },
  "ProductionPlan": {
    "Title": "Kế hoạch sản xuất",
    "NewProductionPlan": "Kế hoạch sản xuất mới",
    "UpdateProductionPlan": "Cập nhật kế hoạch sản xuất",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo mã",
    "Stt": "Stt",
    "Code": "Mã",
    "PostingDate": "Posting date",
    "Note": "Nội dung",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Manufacture": "Sản xuất",
    "ManufactureList": "Danh sách sản xuất",
    "ProductionPlanDetail": "Chi tiết kế hoạch sản xuất",
    "GeneralInfo": "Thông tin chung",
    "RequestDocumentType": "Request document type",
    "RequestDocumentCode": "Request document code",
    "SelectFinishedProductFrom": "Chọn thành phẩm sản xuất từ",
    "AddByProduct": "Thêm sản phẩm phụ",
    "RequestMaterial": "Yêu cầu vật tư",
    "NoMaterialRequirement": "Chưa có yêu cầu. Vui lòng \"Tính toán yêu cầu vật tư \"",
    "CalculateMaterialRequirement": "Tính toán yêu cầu vật tư",
    "UpdateRequestMaterial": "Cập nhật yêu cầu vật tư"
  },
  "ProductionPlanStatus": {
    "New": "Phiếu tạm",
    "Approved": "Phiếu đã được xác nhận",
    "InProgress": "Phiếu đã thực thi",
    "Completed": "Phiếu đã hoàn tất",
    "Cancelled": "Phiếu hủy"
  },
  "RequestDocumentType": {
    "MaterialRequest": "Yêu cầu vật tư",
    "ManufactureOrder": "Đơn đặt hàng sản xuất",
    "PeriodicProductionPlan": "Kế hoạch sản xuất định kì",
    "Other": "Khác"
  },
  "TransferNote": {
    "Title": "Phiếu chuyển kho",
    "NewTransferNote": "Phiếu chuyển kho mới",
    "TransferNoteDetail": "Chi tiết phiếu chuyển kho",
    "CreateTransferNote": "Tạo phiếu chuyển kho",
    "SearchPlaceholder": "Tìm theo mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã phiếu",
    "TransferRequestDate": "Ngày yêu cầu chuyển kho",
    "FromWarehouse": "Kho chuyển",
    "ToWarehouse": "Kho nhận",
    "Status": "Trạng thái",
    "Description": "Nội dung",
    "Note": "Ghi chú",
    "ProductList": "Danh sách sản phẩm",
    "GeneralInfo": "Thông tin chung",
    "ImportInfo": "Thông tin nhập kho",
    "ExportInfo": "Thông tin xuất kho",
    "TransferNoteInfo": "Thông tin chuyển kho",
    "Product": "Sản phẩm",
    "FromProductType": "Loại chuyển",
    "ToProductType": "Loại nhận",
    "Quantity": "Số lượng",
    "Weight": "Trọng lượng",
    "ActualQuantity": "SL thực nhận",
    "ActualWeight": "TL thực nhận",
    "ProductToTransfer": "Sản phẩm chuyển kho",
    "FromWarehouseAllowInternalTransfer": "Kho chuyển chỉ cho chuyển kho nội bộ",
    "ConfirmExport": "Xác nhận xuất kho",
    "ConfirmImport": "Xác nhận nhập kho",
    "ConfirmExportInfo": "Thông tin xác nhận xuất kho",
    "ConfirmImportInfo": "Thông tin xác nhận nhập kho",
    "ActualImportProductList": "Danh sách nhập kho thực tế"
  },
  "SupplyChainManagement": {
    "Title": "Quản lý chuỗi cung ứng",
    "ProcurementAndSourcing": "Procurement and sourcing"
  },
  "Incoterm": {
    "Title": "Incoterm",
    "NewIncoterm": "Incoterm mới",
    "UpdateIncoterm": "Cập nhật incoterm",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái"
  },
  "TaxAndChargeCategory": {
    "Title": "Danh mục thuế phí",
    "NewTaxAndChargeCategory": "Danh mục thuế phí mới",
    "UpdateTaxAndChargeCategory": "Cập nhật danh mục thuế phí",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái"
  },
  "TaxAndCharge": {
    "Title": "Thuế phí",
    "NewTaxAndCharge": "Thuế phí mới",
    "UpdateTaxAndCharge": "Cập nhật thuế phí",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "Rate": "Tỉ lệ",
    "MaxAmount": "Số tiền tối đa",
    "TaxAndChargeApplyType": "Phương thức áp dụng",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "SelectTaxAndCharge": "Chọn thuế phí",
    "Amount": "Amount",
    "TotalAmount": "Total amount",
    "Note": "Ghi chú",
    "Quantity": "Số lượng"
  },
  "TaxAndChargeApplyType": {
    "Rate": "Tính theo %",
    "Amount": "Tính theo số tiền"
  },
  "DiscountApplyType": {
    "BeforeTaxesAndCharges": "Giảm giá trên giá trước thuế - phí",
    "AfterTaxesAndCharges": "Giảm giá trên giá sau thuế - phí"
  },
  "PurchaseOrder": {
    "Title": "Đơn hàng",
    "Supplier": "Nhà cung cấp",
    "Buyer": "Khách hàng",
    "NewPurchaseOrder": "Đơn hàng mới",
    "PurchaseOrderDetail": "Chi tiết đơn hàng",
    "CreateOrder": "Tạo đơn hàng",
    "ProductList": "Danh sách sản phẩm",
    "OrderInfo": "Thông tin đặt hàng",
    "RequestDate": "Ngày đặt hàng",
    "RequestDeliveryDate": "Ngày yêu cầu nhận hàng",
    "MasterOrder": "Master order",
    "Note": "Ghi chú",
    "TotalAmount": "Thành tiền",
    "SubTotal": "Sub total",
    "TaxAndCharge": "Thuế và phí",
    "Discount": "Giảm trừ",
    "NetTotal": "Net total",
    "GrandTotal": "Grand total",
    "RoundAdjustment": "Round adjustment",
    "RoundedTotal": "Rounded total",
    "DisableRoundAdjustment": "Disable round adjustment",
    "BusinessUnit": "Đơn vị đặt hàng",
    "Code": "Mã đơn đặt hàng"
  },
  "ManufactureOrder": {
    "Title": "Đơn hàng sản xuất",
    "Supplier": "Nhà cung cấp",
    "Buyer": "Khách hàng",
    "NewManufactureOrder": "Đơn hàng sản xuất mới",
    "UpdateManufactureOrder": "Cập nhật đơn hàng sản xuất",
    "ManufactureOrderDetail": "Chi tiết đơn hàng sản xuất",
    "CreateOrder": "Tạo đơn hàng",
    "ProductList": "Danh sách sản phẩm",
    "OrderInfo": "Thông tin đặt hàng sản xuất",
    "RequestDate": "Ngày đặt hàng",
    "RequestDeliveryDate": "Ngày yêu cầu nhận hàng",
    "MasterOrder": "Master order",
    "Note": "Ghi chú",
    "TotalAmount": "Thành tiền",
    "SubTotal": "Sub total",
    "TaxAndCharge": "Thuế và phí",
    "Discount": "Giảm trừ",
    "NetTotal": "Net total",
    "GrandTotal": "Grand total",
    "RoundAdjustment": "Round adjustment",
    "RoundedTotal": "Rounded total",
    "DisableRoundAdjustment": "Disable round adjustment",
    "BusinessUnit": "Đơn vị đặt hàng",
    "Code": "Mã đơn đặt hàng",
    "ManufactureStatus": "Tình trạng sản xuất",
    "SelectManufactureOrder": "Chọn đơn hàng sản xuất",
    "SelectByOrderNumber": "Chọn theo mã đơn hàng",
    "OrderItems": "Order items",
    "NewManufactureOrderItem": "New manufacture order item",
    "UpdateManufactureOrderItem": "Update manufacture order item"
  },
  "ManufactureOrderStatus": {
    "New": "Mới",
    "Approved": "Đã xác nhận",
    "Planned": "Đã lên kế hoạch",
    "InProduction": "Đang sản xuất",
    "ProductionCompleted": "Hoàn thành sản xuất",
    "Stored": "Đã nhập kho",
    "Packing": "Đang đóng gói",
    "ReadyToShipment": "Sẵn sàng giao hàng",
    "InTransit": "Đang giao hàng",
    "Completed": "Đã hoàn thành",
    "Cancelled": "Hủy"
  },
  "Discount": {
    "DiscountApplyType": "Phương thức giảm trừ",
    "Amount": "Amount",
    "Rate": "Tỉ lệ",
    "Note": "Ghi chú"
  },
  "AccountManagement": {
    "Title": "Quản lý tài khoản",
    "UserAndAccount": "Người dùng và tài khoản",
    "User": "Người dùng",
    "Account": "Tài khoản",
    "Setting": "Thiết lập"
  },
  "Role": {
    "Title": "Role",
    "NewRole": "New role",
    "UpdateRole": "Update role",
    "SearchPlaceholder": "Tìm theo tên",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "RoleSelect": "Chọn vai trò",
    "RoleDetail": "Chi tiết vai trò",
    "PermissionList": "Danh sách quyền",
    "ResourceList": "Danh sách tài nguyên",
    "RoleInfo": "Thông tin vai trò"
  },
  "Permission": {
    "Title": "Permission",
    "NewPermission": "New permission",
    "UpdatePermission": "Update permission",
    "AddPermission": "Thêm quyền",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "PermissionInfo": "Thông tin quyền"
  },
  "Resource": {
    "Title": "Resource",
    "NewResource": "New resource",
    "UpdateResource": "Update resource",
    "AddResource": "Thêm tài nguyên",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "ResourceUniqueCode": "Mã tài nguyên duy nhất",
    "IsActive": "Trạng thái",
    "ResourceType": "Resource type",
    "ResourceInfo": "Thông tin tài nguyên"
  },
  "ResourceType": {
    "Module": "Module",
    "Features": "Features",
    "Group": "Group",
    "Function": "Function"
  },
  "WarehouseManagement": {
    "Title": "Quản lý kho",
    "InboundOutbound": "Inbound & outbound",
    "Inventory": "Tồn kho"
  },
  "Location": {
    "Title": "Location",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "NewLocation": "New location",
    "UpdateLocation": "Update location",
    "Stt": "Stt",
    "Code": "Mã",
    "Name": "Tên",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "AutoGenerateBinLocation": "Auto generate bin location",
    "Aisle": "Aisle",
    "Rack": "Rack",
    "Shelf": "Shelf",
    "Bin": "Bin",
    "Prefix": "Prefix",
    "FromNumber": "From number",
    "ToNumber": "To number",
    "Length": "Length",
    "StaticText": "Static text"
  },
  "BinLocation": {
    "Title": "Bin location",
    "NewBinLocation": "New bin location",
    "UpdateBinLocation": "Update bin location",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã",
    "Note": "Mô tả",
    "IsActive": "Trạng thái"
  },
  "Batch": {
    "Title": "Batch",
    "NewBatch": "New batch",
    "UpdateBatch": "Update batch",
    "SearchPlaceholder": "Tìm theo mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã",
    "Note": "Mô tả",
    "IsActive": "Trạng thái",
    "ExpirationDate": "Expiration date",
    "ManufactureDate": "Ngày sản xuất",
    "Description": "Mô tả"
  },
  "WarrantyType": {
    "NoWarranty": "Không bảo hành",
    "FixedTerm": "Bảo hành cố định",
    "LifeTime": "Bảo hành trọn đời",
    "Extended": "Bảo hành được mở rộng",
    "UsageBased": "Bảo hành theo thời gian sử dụng thực tế",
    "Conditional": "Bảo hành với điều kiện cụ thể",
    "LimitedTime": "Bảo hành thời hạn ngắn",
    "ServiceBased": "Bảo hành theo lịch sử bảo trì",
    "Other": "Bảo hành khác"
  },
  "WarrantyCoverageType": {
    "Global": "Bảo hành toàn cầu",
    "Regional": "Bảo hành theo khu vực",
    "Domestic": "Bảo hành nội địa",
    "OnSite": "Bảo hành tại chổ",
    "CarryIn": "Bảo hành tại địa điểm chỉ định",
    "International": "Bảo hành quốc tế"
  },
  "Warranty": {
    "Title": "Warranty",
    "NewWarranty": "New warranty",
    "UpdateWarranty": "Update warranty",
    "SearchPlaceholder": "Tìm theo tên sản phẩm",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "ExpirationWarrantyDate": "Expiration warranty date",
    "DurationInUnit": "Duration in unit",
    "IsExtended": "Is extended",
    "Provider": "Nhà cung cấp",
    "TermsAndConditions": "Terms and conditions",
    "WarrantyType": "Loại bảo hành",
    "WarrantyCoverageType": "Warranty coverage type",
    "IsActive": "Trạng thái"
  },
  "Serial": {
    "Title": "Serial",
    "NewSerial": "New serial",
    "UpdateSerial": "Update serial",
    "SearchPlaceholder": "Tìm theo mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Code",
    "AmcExpiryDate": "Amc expiry date",
    "ItemGroup": "Item group",
    "WarrantyStartDate": "Warranty start date",
    "WarrantyEndDate": "Warranty end date",
    "TermsAndConditions": "Terms and conditions",
    "ClaimCount": "Claim count",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Status": "Tình trạng"
  },
  "ItemGroup": {
    "Consumable": "Vật tư tiêu hao",
    "Product": "Sản phẩm",
    "RawMaterial": "Nguyên vật liệu",
    "Service": "Dịch vụ",
    "SubAssembly": "Cụm linh kiện, tổ hợp"
  },
  "SerialStatus": {
    "Available": "Chưa sử dụng",
    "InUse": "Đã được sử dụng"
  },
  "LotNumber": {
    "Title": "Lot number",
    "NewLotNumber": "New lot number",
    "UpdateLotNumber": "Update lot number",
    "SearchPlaceholder": "Tìm theo mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Code",
    "ReceivedDate": "Received date",
    "ExpirationDate": "Expiration date",
    "Supplier": "Nhà cung cấp",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Status": "Tình trạng"
  },
  "Package": {
    "Title": "Package",
    "NewPackage": "New package",
    "UpdatePackage": "Update package",
    "PackageDetail": "Package detail",
    "SearchPlaceholder": "Tìm theo package number, lot number hoặc bin location",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "PackageNumber": "Package number",
    "ExpirationDate": "Expiration date",
    "Quantity": "Quantity",
    "Weight": "Weight",
    "TotalQuantity": "Total quantity",
    "TotalWeight": "Total weight",
    "Volume": "Total volume",
    "Dimensions": "Dimensions",
    "HandlingInstructions": "Handling instructions",
    "IsActive": "Trạng thái",
    "Description": "Mô tả",
    "PackageInfo": "Package info",
    "PackageItems": "Package items",
    "PackingDate": "Packing date",
    "Manufacturer": "Manufacturer",
    "ItemType": "Item type",
    "Item": "Item"
  },
  "ItemType": {
    "Component": "Vật tư",
    "Product": "Sản phẩm"
  },
  "PutAwayOrder": {
    "Title": "Put away order",
    "NewPutAwayOrder": "New put away order",
    "UpdatePutAwayOrder": "Update put away order",
    "PutAwayOrderDetail": "Put away order detail",
    "SearchPlaceholder": "Tìm theo mã",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "PutAwayOrderNumber": "Put away order number",
    "PutAwayDate": "Put away date",
    "PutAwayStatus": "Status",
    "AssignedEmployee": "Assigned employee",
    "Warehouse": "Kho lưu",
    "Note": "Ghi chú",
    "Status": "Trạng thái",
    "PutAwayOrderItems": "Put away order items",
    "PutAwayOrderDetails": "Put away order details",
    "PackageList": "Danh sách package",
    "DocumentInfo": "Thông tin chứng từ",
    "PutAwayInfo": "Thông tin phiếu lưu hàng",
    "IsActive": "Trạng thái",
    "AddPackage": "Add package",
    "AddDocument": "Add document",
    "Document": "Document",
    "DocumentType": "Document type"
  },
  "PutAwayStatus": {
    "New": "Phiếu mới",
    "InProgress": "Phiếu đang giao",
    "Completed": "Phiếu đã giao",
    "Cancelled": "Phiếu đã hủy",
    "Expired": "Phiếu đã quá hạn"
  },
  "DocumentType": {
    "WarehouseNoteImport": "Warehouse note import",
    "WarehouseNoteExport": "Warehouse note export",
    "DeliveryNote": "Delivery note"
  },
  "Image": {
    "SelectImage": "Chọn hình ảnh",
    "UploadSuccessfully": "Tải lên thành công"
  },
  "QualityInspection": {
    "Title": "Phiếu kiểm định",
    "NewButton": "Thêm mới",
    "SearchPlaceholder": "Tìm theo mã phiếu",
    "NewQualityInspection": "Phiếu kiểm định mới",
    "UpdateQualityInspection": "Cập nhập phiếu kiểm định",
    "QualityInspectionDetail": "Chi tiết phiếu kiểm định",
    "Stt": "Stt",
    "NoteNumber": "Mã phiếu",
    "ReportDate": "Report date",
    "ManualInspection": "Manual inspection",
    "Type": "Loại",
    "Status": "Trạng thái",
    "InspectedBy": "Inspected by",
    "VerifiedBy": "Verified by",
    "InspectedByEmployee": "Inspected by employee",
    "VerifiedByEmployee": "Verified by employee",
    "NoteItems": "Note items",
    "NoteInfo": "Thông tin phiếu kiểm định",
    "InspectionType": "Loại phiếu kiểm định",
    "DocumentType": "Document type",
    "Document": "Document",
    "Note": "Ghi chú",
    "ItemCode": "Item code",
    "ItemGroup": "Item group",
    "SampleSize": "Sample size",
    "Remark": "Remark",
    "Parameter": "Thông số kiểm định",
    "InspectionReading": "Inspection reading",
    "InspectionParameters": "Inspection paramaters",
    "NewQualityInspectionItem": "New quality inspection item",
    "UpdateQualityInspectionItem": "Update quality inspection item",
    "Value": "Value",
    "Accepted": "Accepted",
    "Rejected": "Rejected",
    "Result": "Kết quả"
  },
  "InspectionType": {
    "In": "In",
    "Out": "Out",
    "Manufacturing": "Manufacturing"
  },
  "QualityInspectionStatus": {
    "New": "Phiếu mới",
    "InProgress": "Đang kiểm định",
    "Pass": "Thông qua",
    "Fail": "Thất bại",
    "AcceptedWithDeviation": "Chấp nhận với điều kiện",
    "Pending": "Hoãn"
  },
  "AccountantBook": {
    "BankBook": "Sổ ngân hàng",
    "CashBook": "Sổ tiền mặt",
    "SearchPlaceholder": "Tìm theo mã",
    "FromDate": "Từ ngày",
    "ToDate": "Đến ngày",
    "Stt": "Stt",
    "EffectiveDate": "Ngày hạch toán",
    "CreatedDate": "Ngày chứng từ",
    "ReceiptNumber": "Số phiếu thu",
    "PaymentNumber": "Số phiếu chi",
    "OpeningTransactionAmount": "Đầu kỳ",
    "PostTransactionAmount": "Tồn",
    "Amount": "Số tiền",
    "Note": "Diễn giải",
    "Payer": "Người chi",
    "Payee": "Người nhận",
    "PayerPayee": "Người nộp / chi",
    "Cashier": "Kế toán thu / chi",
    "BusinessUnit": "Đơn vị kinh doanh",
    "Receiver": "Người nhận",
    "BankAccountNumber": "Số tài khoản ngân hàng",
    "TransactionNumber": "Số giao dịch"
  },
  "Transportation": {
    "Stt": "Stt",
    "Title": "Phương tiện",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "NewTransportation": "Phương tiện mới",
    "UpdateTransportation": "Cập nhật phương tiện",
    "IsInLand": "Is in land",
    "IsAir": "Is air",
    "IsOcean": "Is ocean"
  },
  "Shipment": {
    "Title": "Phiếu vận hàng",
    "SearchPlaceholder": "Tìm theo mã phiếu vận hàng",
    "NewButton": "Thêm mới",
    "NewShipment": "Phiếu vận hàng mới",
    "ShipmentInfo": "Thông tin phiếu vận hàng",
    "ShipmentDetail": "Chi tiết  phiếu vận hàng",
    "Stt": "Stt",
    "Code": "Mã",
    "ShipmentNumber": "Mã vận hàng",
    "DeliveryDate": "Ngày giao",
    "Status": "Trạng thái",
    "Courier": "Đơn vị vận chuyển",
    "ShippingMethod": "Phương thức giao hàng",
    "SelectDeliveryNote": "Select delivery note",
    "NumberOfPackage": "Số thùng",
    "SelectNumberOfPackageToDeliver": "Chọn số lượng thùng cần giao",
    "DeliveryAll": "Giao tất cả",
    "DeliveryPartially": "Giao 1 phần",
    "ShipmentRouting": "Shipment routing",
    "PickupLocation": "Pick up location",
    "Store": "Cửa hàng",
    "Warehouse": "Kho",
    "OtherLocation": "Địa điểm khác",
    "StoreList": "Danh sách cửa hàng",
    "WarehouseList": "Danh sách kho",
    "Routing": "Routing",
    "ShipperBackToPickup": "Shipper quay vể điểm nhận hàng",
    "EstimatedDistance": "Số km dự kiến",
    "AddDeliveryLocation": "Thêm địa điểm giao hàng",
    "DeliveryLocationIncurred": "Địa điểm giao hàng phát sinh",
    "SelectCustomerDeliveryLocation": "Chọn địa điểm giao hàng của khách hàng",
    "OtherDeliveryLocation": "Địa điểm khác",
    "GeneralInfo": "General info",
    "DeliveryMethod": "Phương thức giao hàng",
    "DeliveryScope": "Phạm vi giao hàng",
    "CourierAndShipper": "Courier and shipper",
    "Document": "Chứng từ",
    "MasterBill": "Master bill",
    "SaveInfo": "Lưu thông tin",
    "InternalDelivery": "Giao nội bộ",
    "DeliveryParty": "Bên giao hàng",
    "DeliverySlip": "Delivery slip",
    "Shipper": "Shipper",
    "NumberOfPackageError": "Số thùng không hợp lệ",
    "Other": "Khác",
    "Transportation": "Phương tiện",
    "Note": "Ghi chú",
    "DeliverySlips": "Delivery slips",
    "ViewDeliveryNote": "Xem phiếu giao hàng",
    "CompleteDeliverySlip": "Hoàn thành giao hàng",
    "DeliverySlipInfo": "Thông tin vận đơn",
    "DeliveryImage": "Hình ảnh giao hàng",
    "AddDeliverySlip": "Thêm vận đơn",
    "Complete": "Hoàn thành",
    "Assign": "Assign",
    "InTransit": "In transit",
    "Deliver": "Deliver",
    "Cancel": "Cancel",
    "Fail": "Fail"
  },
  "ShipmentStatus": {
    "New": "Phiếu mới",
    "Assigned": "Phiếu đã phân phối",
    "InTransit": "Phiếu đang giao",
    "Delivered": "Phiếu đã giao",
    "Completed": "Xác nhận hoàn thành",
    "Cancelled": "Hủy giao hàng trước khi giao",
    "Failed": "Hủy giao hàng do sự cố"
  },
  "DeliverySlipStatus": {
    "New": "Phiếu mới",
    "InProgress": "Phiếu đang giao",
    "Completed": "Phiếu đã giao",
    "Cancelled": "Phiếu đã hủy",
    "Expired": "Phiếu quá hạn"
  },
  "DeliveryScope": {
    "Domestic": "Giao hàng nội địa",
    "International": "Giao hàng quốc tế"
  },
  "DeliveryMethod": {
    "InLand": "Đường bộ",
    "Ocean": "Đường biển",
    "Air": "Đường hàng không",
    "Multimodal": "Kết hợp"
  },
  "DeliveryParty": {
    "Internal": "Giao hàng nội bộ",
    "External": "Giao hàng do đơn vị khác"
  },
  "MaterialRequest": {
    "Title": "Yêu cầu vật tư",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewMaterialRequest": "Yêu cầu vật tư mới",
    "UpdateMaterialRequest": "Cập nhập yêu cầu vật tư",
    "MaterialRequestDetail": "Chi tiết yêu cầu vật tư",
    "NewButton": "Thêm mới",
    "Stt": "Stt",
    "Code": "Mã",
    "Note": "Ghi chú",
    "TransactionDate": "Ngày yêu cầu",
    "RequestBy": "Yêu cầu bởi",
    "ApproveBy": "Xác nhận bởi",
    "Status": "Trạng thái",
    "Type": "Loại",
    "MaterialRequestItem": "Material request item",
    "SelectRequester": "Chọn người yêu cầu",
    "SelectApprover": "Chọn người xác nhận",
    "SelectMaterialRequest": "Chọn yêu cầu vật tư",
    "SelectByCode": "Chọn theo mã"
  },
  "MaterialRequestStatus": {
    "New": "Phiếu tạm",
    "Approved": "Phiếu đã được xác nhận",
    "InProgress": "Phiếu đã thực thi",
    "Completed": "Phiếu đã hoàn tất",
    "Cancelled": "Phiếu hủy"
  },
  "MaterialRequestType": {
    "Purchase": "Đặt mua hàng",
    "CustomerProvided": "Yêu cầu khách hàng cung cấp",
    "MaterialTransfer": "Chuyển kho nội bộ",
    "MaterialIssue": "Xuất kho sử dụng",
    "Manufacture": "Xuất kho sản xuất",
    "Other": "Khác"
  },
  "ProductManufacture": {
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "NewProductManufacture": "New component mapping",
    "UpdateProductManufacture": "Update component mapping",
    "Stt": "Stt",
    "Note": "Ghi chú",
    "IsActive": "Trạng thái"
  }
}