import { NavigationItem } from './navigation.model';

export const defaultNavigation: NavigationItem[] = [
  {
    id: 'group-1',
    title: 'Group 1',
    type: 'group',
    children: [
      {
        id: 'SM',
        title: 'Navigation.SalesManagement',
        type: 'basic',
        icon: 'design:cart',
        link: '/sale-management',
      },
      {
        id: 'WM',
        title: 'Navigation.WarehouseManagement',
        type: 'basic',
        icon: 'design:sale_inventory',
        link: '/warehouse-management',
      },
      {
        id: 'CRM',
        title: 'Navigation.CRM',
        type: 'basic',
        icon: 'design:client_group',
        link: '/crm',
      },
      {
        id: 'SCM',
        title: 'Navigation.SupplyChainManagement',
        type: 'basic',
        icon: 'design:supply_chain',
        link: '/supply-chain-management',
      },
      {
        id: 'MM',
        title: 'Navigation.ManufacturingManagement',
        type: 'basic',
        icon: 'design:factory',
        link: '/manufacture-management',
      },
    ],
  },
  {
    id: 'group-4',
    title: 'Group 4',
    type: 'group',
    classes: {
      wrapper: 'mt-auto',
    },
    children: [
      {
        id: 'AC',
        title: 'AccountManagement.Title',
        type: 'basic',
        icon: 'design:customer',
        link: '/account-management',
      },
      {
        id: 'SC',
        title: 'Navigation.SettingAndConfiguration',
        type: 'basic',
        icon: 'design:setting',
        link: '/settings',
      },
    ],
  },
];
