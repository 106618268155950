<!--<button class="rounded-full p-2" [matMenuTriggerFor]="languagesMenu">-->
<!--  <div class="flex flex-col">-->
<!--    <img-->
<!--      class="w-12 h-12 rounded-full overflow-hidden object-cover"-->
<!--      [src]="selectedLanguage?.imgUrl"-->
<!--      alt=""-->
<!--    />-->
<!--  </div>-->
<!--</button>-->

<!--&lt;!&ndash; User menu &ndash;&gt;-->
<!--<mat-menu #languagesMenu="matMenu" class="text-grey-500">-->
<!--  <ng-template matMenuContent>-->
<!--    <button mat-menu-item *ngFor="let language of languages" (click)="onLanguageChanged(language)">-->
<!--      <img-->
<!--        class="w-8 h-8 rounded-full overflow-hidden object-cover mr-3"-->
<!--        [src]="language.imgUrl"-->
<!--        alt=""-->
<!--      />-->
<!--      <span>{{ language.label }}</span>-->
<!--    </button>-->
<!--  </ng-template>-->
<!--</mat-menu>-->

<div
  class="cursor-pointer flex items-center justify-between"
  (click)="onLanguageChanged(selectedLanguage)"
>
  <div
    class="flex items-center rounded bg-black overflow-hidden slider-cradle relative"
  >
    <button
      class="uppercase text-white w-1/2 text-center cursor-pointer"
      *ngFor="let language of languages"
    >
      {{ language.locale }}
    </button>
    <div
      class="handle rounded absolute w-[30px] h-[26px] cursor-pointer top-[2px] left-[2px] bg-white flex items-center justify-center"
      [ngClass]="
        selectedLanguage.value == languageCode.Vietnamese
          ? 'is-left'
          : 'is-right'
      "
    >
      <!-- <img
        class="overflow-hidden object-cover w-[22px]"
        [src]="selectedLanguage?.imgUrl"
        alt=""
      /> -->
    </div>
  </div>
</div>
